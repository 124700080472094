import { Environment } from './../../stores/environment.store';
import { Student } from './../../stores/student.store';
import { Auth } from './../../stores/auth.store';
import { exploreItems } from './../../dummy/explore';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  explores = exploreItems;
  carouselBanner = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    slide: 3,
    speed: 400,
    point: { visible: false },
    load: 2,
    loop: true,
    touch: true
  };
  admission: any;
  schedules: any;
  constructor(
    public auth: Auth,
    public store: Student,
    public env: Environment,
    public router: Router,
    public payment: Payment,

  ) { }

  async ngOnInit() {
    this.env.fetchConfigToArray(config => {
      this.auth.fetchAccountLink(account => {
        if (account) {
          this.store.fetchStudent(account.studentKey);
          this.store.fetchAdmission(account.studentKey,async (doc) => {
            const { term } = config;
            const { program_academic } = this.store.selectedAdmission;
            this.env.fetchInstituteTerm(program_academic.category.key);
            this.store.fetchStudentScheduleTerm(this.env.selectedTerm.key, account.studentKey)
            this.payment.fetchStudentInvoices(account.studentKey);
          })
        }
        else {
          this.router.navigate(['/not-found-account']);
        }
      })
    })
  }

}
