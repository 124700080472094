import { DataService } from './../services/data.service';
import { observable, action } from 'mobx';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MappingService, pushToArray, pushToObject } from '../services/mapping.service';
import { Router } from '@angular/router';

@Injectable()
export class Environment {
  @observable public data = null;
  @observable public selectedTerm = null;
  @observable public user = null;
  @observable public account = null;
  @observable public loading = false;
  @observable public options = null;
  @observable public canEnroll = false;
  @observable public scheduleType = null;
  @observable public process = false;
  @observable institute = null;
  @observable public tabs = [];


  constructor(private ds: DataService
    , private router: Router
    , private auth: AuthService) {


  }


  @action
  async fetchConfiguration(shiftKey) {
    this.loading = true;
    const docs = await this.ds.academicRef().get().toPromise();
    this.data = pushToObject(docs);
    if (docs.exists) {
      const { term, can_enroll } = this.data;
      this.selectedTerm = term;
      this.canEnroll = can_enroll;
    }
    this.loading = false;
    return this.data;
  }

  action
  async fetchSelectedTerm(termKey, scheduleType, shiftKey) {
    this.scheduleType = scheduleType;
    if (termKey) {
      const docData = await this.ds.academicTermDocRef().doc(termKey).get().toPromise();
      this.selectedTerm = pushToObject(docData);
    }
    return this.selectedTerm;
  }

  @action
  async fetchAccount() {
    const { uid, displayName, email, phoneNumber, photoURL } = this.auth.authRef().auth.currentUser;
    return { uid, displayName, email, phoneNumber, photoURL }
  }

  @action
  async fetchInstituteTerm(instituteKey) {
    this.loading = true;
    const termDoc = await this.ds.instituteTermRef(instituteKey).get().toPromise();
    let term = null;
    if (!termDoc.empty) {
      const list = MappingService.pushToArray(termDoc);
      this.selectedTerm = list[0];
      term = list[0];
    }
    this.loading = false;
  }

  @action
  async fetchInstituteTermDoc(instituteKey, callback) {
    this.loading = true;
    const termDoc = await this.ds.instituteTermRef(instituteKey).get().toPromise();
    if (!termDoc.empty) {
      const list = MappingService.pushToArray(termDoc);
      this.selectedTerm = list[0];
    }
    this.loading = false;
    callback(this.selectedTerm);
  }

  @action
  async fetchTerms() {
    const docData = pushToArray(await this.ds.termAvailableRef().get().toPromise());
    // const list = docData.filter(m => m.term_type.type !== 'English');
    return docData;
  }

  @action
  async fetchCurrentTermInstituteToObject(instituteKey, callback) {
    this.process = true;
    const instituteDoc = await this.ds.instituteRef().doc(instituteKey).get().toPromise();
    this.institute = MappingService.pushToObject(instituteDoc)
    const termDoc = await this.ds.paymentTermRef(instituteKey).get().toPromise();
    this.selectedTerm = MappingService.pushToObject(termDoc);
    this.process = false;
    callback(this.selectedTerm)
  }

  @action
  fetchConfig() {
    this.loading = true;
    this.ds
      .academicRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        if (docs) {
          this.canEnroll = docs.can_enroll;
          this.selectedTerm = docs.term
        }
        this.loading = false;
      });
  }

  @action
  fetchConfigToArray(callback) {
    this.loading = true;
    this.ds
      .academicRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        if (docs) {
          this.selectedTerm = docs.term;
          this.canEnroll = docs.can_enroll;
        }
        this.loading = false;
        callback(docs)
      });
  }

  @action
  async fetchOption() {
    const doc = await this.ds.configRef().get().toPromise();
    return pushToObject(doc);
  }

  @action
  fetchOptionsToArray(callback) {
    this.ds
      .configRef()
      .valueChanges()
      .subscribe(doc => {
        this.options = doc;
        callback(doc);
      });
  }
}
