import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { Student } from 'src/app/stores/student.store';

@Component({
  selector: 'app-task-bar',
  templateUrl: './task-bar.component.html',
  styleUrls: ['./task-bar.component.scss']
})
export class TaskBarComponent implements OnInit {
  @Output() onPrint=new EventEmitter();
  @Input() transcript: any;
  icons=[
    "school",
    "cloud",
    "view_carousel",
    "favorite",
    "verified_user",
  ]
  constructor(public store:Student) { }

  ngOnInit() {
  }

  _onPrint(){
    this.onPrint.emit();
  }

}
