import { Component, OnInit } from '@angular/core';
import { Cart } from 'src/app/stores/cart.store';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { Auth } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-request-success',
  templateUrl: './request-success.component.html',
  styleUrls: ['./request-success.component.scss']
})
export class RequestSuccessComponent implements OnInit {

  constructor(
    public store: Auth,
    public student: Student,
    public env: Environment,
    public cart: Cart,
  ) { }

  ngOnInit() {
    this.env.fetchConfigToArray(doc=>{
      this.store.fetchAccountLink(acc=>{
        this.cart.fetchRequestedCart(this.env.selectedTerm.key,this.store.student.key);
      })
    })
  }

}
