import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {
@Input() buttonText:string;
@Input() subtitle:string;
@Input() name:string;
@Input() className:string;
@Output() onPress=new EventEmitter();
@Input() photo:string;
  constructor() { }

  ngOnInit() {
  }

  create(){
    this.onPress.emit();
  }

}
