import { Component, ElementRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PUC Cloud';
  constructor(private swUpdate: SwUpdate,private _elementRef: ElementRef) {}

  ngOnInit() {
    this._elementRef.nativeElement.removeAttribute("ng-version");
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {     
          window.location.reload();        
      });
    }
  }
}
