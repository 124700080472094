import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService } from "../services/convert.service";
import { MappingService } from "../services/mapping.service";
import { Student } from './student.store';
import { enrollStatusObj, invoiceTypesObj, enrollPrograms, paymentStatus, EnrollStatus, paymentType } from "../dummy/status";
import { IInvoice } from "../interfaces/invoice";

@Injectable()
export class Payment {
  @observable
  public invoice = null;
  @observable
  public invoiceHeader = null;
  @observable
  public totalTuitionFee = null;
  @observable
  public totalSubject = null;
  @observable
  public otherFee = null;
  @observable
  public totalCashScholarship = 0;
  @observable
  public totalCashLoan = 0;

  @observable
  public student = null;
  @observable
  public filterType = null;

  @observable
  public data = null;

  @observable
  public loading = false;
  @observable
  public process = false;
  @observable
  public header = null;
  @observable
  public detail = null;
  @observable
  public totalPrice = null;
  @observable
  public subtotal = null;
  @observable
  public enroll = null;
  @observable
  public empty = false;


  @observable public scholarshipRef = null;
  @observable public penaltyRef = null;
  @observable public scholarship = null;

  @observable public enrollIncluded = null;
  @observable public enrollExcluded = null;

  @observable public studentScholarship = null;
  @observable public totalScholarship = null;
  @observable public totalLoan = null;
  @observable public totalPrepaid = null;
  @observable public totalInstallment = null;
  @observable public totalPenalty = null;
  @observable public grandTotal = null;
  @observable public gradePrice = null;
  @observable public totalLate = null;
  @observable public totalScholarShipPenalty = null;
  @observable public scholarshipPenalty = null;

  @observable public receiptHeader = null;
  @observable public totalTestingFee = null;
  @observable public totalMiscellaneous = null;
  @observable public totalScholarshipPenalty = null;
  @observable public allInvoices = null;
  @observable public unpaidInvoices = null;
  @observable public scholarshipExamInvoice = null;


  @observable public paymentEmpty = false;
  @observable.shallow public paymentHeader = null;
  @observable public paymentDetail = null;
  @observable public paymentTuitionFee = [];
  @observable public paymentScholarshipPenalty = [];
  @observable public paymentMiscellaneous = [];
  @observable public totalDiscount = null;

  constructor(
    private ds: DataService,
    private stu: Student,
  ) { }

  // @action
  // fetchStudentScholarship(studentKey, callback) {
  //   this.process = true;
  //   this.ds.scholarshipByStudent(studentKey).valueChanges().subscribe(docs => {
  //     this.scholarshipRef = docs.length > 0 ? docs[0] : null;
  //     this.process = false;
  //     callback(this.scholarshipRef)
  //   });
  // }

  @action
  fetchScholarshipByProgram(studentKey: string, programKey: string, callback) {
    this.loading = true;
    this.scholarshipRef = null;
    this.ds.studentScholarshipByProgram(studentKey, programKey).valueChanges().subscribe(doc => {
      this.loading = false;
      if (doc.length > 0) this.scholarship = doc[0];
      callback(doc)
    })
  }

  @action
  fetchInvoiceByStudentKey(studentKey: string, callback) {
    this.process = true;
    this.receiptHeader = null;
    this.ds.invoiceItemsByStudentKeyRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length == 0;
      this.invoiceHeader = docs.length > 0 ? docs[0] : null;
      if (docs.length > 0) {
        this.fetchInvoiceItems(studentKey, this.invoiceHeader.headerRef, req => {
          this.process = false;
          callback(this.invoiceHeader);
        })
      }
      else {
        this.process = false;
        callback(this.invoiceHeader);
      }
    })
  }
  @action
  fetchStudentInvoices(studentKey: string) {
    this.loading = true;
    this.ds.invoicesStudentRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      if (docs && docs.length > 0) {
        const data = docs.filter(m => m.isPaid.key == paymentStatus.paid.key);
        const dataUnpaid = docs.filter(m => m.isPaid.key == paymentStatus.unpaid.key);
        this.scholarshipExamInvoice = docs.filter(m => m.invoice_type.key === invoiceTypesObj.scholarship_exam.key);
        this.allInvoices = MappingService.orderByDesc(data, "page_key");
        this.unpaidInvoices = MappingService.orderByDesc(dataUnpaid, "page_key");
      }
      this.loading = false;
    })
  }

  @action
  fetchInvoiceHeader(studentKey: string, hederRef: string, callback) {
    this.loading = true;
    let header = null;
    this.ds.invoicesStudentRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      if (docs && docs.length > 0) {
        const invoices = docs.filter(m => m.headerRef === hederRef);

        if (invoices && invoices.length > 0) {
          header = invoices.filter(m => m.isPaid.key == paymentStatus.unpaid.key)[0];
          this.ds.wingInvoiceRef().doc(header.key).set(header).then(() => {
            this.loading = false;
            callback(header)
          })
        } else {
          this.loading = false;
          callback(header);
        }
      } else {
        this.loading = false;
        callback(header);
      }
    })
  }


  @action
  fetchInvoiceItems(studentKey: string, headerKey: string, callback) {
    this.enrollExcluded = null;
    this.enrollIncluded = null;
    this.enroll = null;
    this.empty = true;
    this.totalTuitionFee = null;
    this.totalTestingFee = null;
    this.otherFee = null;
    this.totalScholarShipPenalty = null;
    this.totalScholarship = null;
    this.totalMiscellaneous = null;
    this.totalPrepaid = null;
    this.totalInstallment = null;
    this.ds.invoiceItemsRef(studentKey, headerKey).valueChanges().subscribe(docs => {
      this.receiptHeader = docs.filter(m => m.isHeader === true)[0];
      //=====TESTING
      const { invoice_type } = this.receiptHeader;
      if (invoice_type && (invoice_type.key === invoiceTypesObj.testing.key)) {
        this.totalTestingFee = this.receiptHeader.amount;
      } else {
        const tuitionFees = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
        const totalFee = MappingService.sumTuitionFees(tuitionFees);
        if (totalFee > 0) this.totalTuitionFee = totalFee;
        this.otherFee = docs.filter(m => m.invoice_type.key !== 2 && m.isHeader == false);
        this.totalSubject = tuitionFees.length;
      }
      callback(docs);
    })
  }

  // @action
  // fetchEnrollment(studentKey, termKey, scholarship: any, prepaid: any, term: any, lateFee: any, callback) {
  //   this.loading = true;
  //   this.enroll = null;
  //   this.totalPenalty = null;
  //   this.totalScholarship = null;
  //   this.totalPrepaid = null;
  //   this.totalPrice = null;
  //   this.grandTotal = null;
  //   this.totalLate = null;
  //   this.invoice = null;
  //   this.invoiceHeader = null;
  //   this.totalTuitionFee = null;

  //   this.ds.studentEnrollmentRef(termKey, studentKey).valueChanges().subscribe(docs => {
  //     this.enroll = docs;
  //     let totalCash = 0;
  //     const mapAmount = docs.map(m => ({ amount: m.fee.price * m.subject.credit }))

  //     //has enrollment
  //     if (docs.length > 0) {
  //       this.totalPrice = MappingService.sum(mapAmount, 'amount');
  //       let scholarship = this.scholarshipRef;
  //       if (scholarship) {
  //         this.totalCashScholarship = MappingService.calCashScholarship(this.totalPrice, scholarship);
  //         this.totalCashLoan = MappingService.calCashLoan(this.totalPrice, scholarship);

  //       }

  //       const { endPaymentKey } = term;
  //       const todayKey = ConvertService.toDateKey(new Date);
  //       if (todayKey > endPaymentKey) {
  //         this.totalPenalty = lateFee.price;
  //         this.totalLate = todayKey - endPaymentKey;
  //       }
  //       if (prepaid) {
  //         this.totalPrepaid = ConvertService.toNull(prepaid.amount);
  //       }

  //       this.grandTotal = ConvertService.toFloatFixed2(this.totalPrice - (this.totalCashScholarship + this.totalCashLoan));
  //     }
  //     // this.grandTotal=
  //     // (ConvertService.toNumber(this.totalPrice)+ConvertService.toNumber(0)+ConvertService.toNumber(this.totalPenalty))
  //     // -(ConvertService.toNumber(this.totalScholarship)+ConvertService.toNumber(this.totalPrepaid));
  //     this.empty = docs.length === 0;

  //     this.loading = false;
  //     callback(docs);
  //   })
  // }

  @action
  fetchPayments(admissionKey: string, studentKey: string, invoiceKey, callback) {
    this.loading = true;
    this.paymentEmpty = false;
    this.paymentHeader = null;
    this.paymentDetail = null;
    this.ds.studentInvoiceByAdmission(admissionKey, studentKey, invoiceKey).valueChanges().subscribe(allInv => {
      let inv = [];
      if (allInv && allInv.length > 0) {
        inv = allInv.filter(m => m.isVoid === false);
      }
      let headerKey = null;
      let data = [];
      const docs = inv.filter(m => m.invoice_type.key !== invoiceTypesObj.scholarshipPenalty.key);
      // if unpaid payment
      if (invoiceKey) {
        data = docs.filter(m => m.course !== null && m.course.key === "unpaid" && m.isHeader === true);
        headerKey = data.length > 0 ? data[0].key : null;
        if (data.length == 0) {
          data = docs.filter(m => m.isHeader === true && m.headerRef == invoiceKey);
          headerKey = invoiceKey;
        }
      } else {//if enrollment payment
        data = docs.filter(m => m.course === null && m.isHeader === true);
        headerKey = data.length > 0 ? data[0].key : null;
      }
      //if invoice by scholarship penalty

      //end scholarship penalty
      this.paymentHeader = docs.length > 0 ? docs.filter(m => m.isHeader === true && m.headerRef == headerKey)[0] : null;
      this.paymentDetail = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.headerRef == headerKey) : [];
      this.paymentEmpty = this.paymentDetail.length === 0;
      this.loading = false;
      const invoices = docs.filter(m => m.headerRef === headerKey)
      callback(invoices);
    })
  }


  @action
  async fetchStudentPayment(studentKey: string, invoiceKey: string, callback) {
    this.loading = true;
    const settingDoc = await this.ds.sysSetting().get().toPromise();
    const setting = MappingService.pushToObject(settingDoc);

    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    const student: any = MappingService.pushToObject(studentDoc)

    let isWaivePenalty = false
    if (student && student.waive_penalty_expired_date_key && student.waive_penalty_expired_date_key >= ConvertService.toDateKey(new Date())) {
      isWaivePenalty = true
    }

    const invoiceDoc = await this.ds.studentDocument(studentKey).collection("invoices").doc(invoiceKey).get().toPromise();
    const invoiceData = MappingService.pushToObject(invoiceDoc);
    const { program } = invoiceData;

    const admissionDoc = await this.ds.admissionDocRef(program.admissionKey).get().toPromise();
    const admission = MappingService.pushToObject(admissionDoc)

    if (admission) {
      const { scholarshipKey } = admission.program_academic;
      if (scholarshipKey) {
        const scholarshipDoc = await this.ds.studentDocument(studentKey).collection("scholarships", ref => ref.where("program.key", "==", scholarshipKey)).get().toPromise();
        this.scholarship = MappingService.pushToArray(scholarshipDoc) ? MappingService.pushToArray(scholarshipDoc)[0] : null
      }
    }

    this.fetchPayments(admission.key, student.key, invoiceKey, async (docs) => {
      let tuitionListing = [];
      this.paymentTuitionFee = [];
      this.paymentScholarshipPenalty = [];
      this.paymentMiscellaneous = [];
      if (docs.length > 0 && !invoiceKey) {
        const snapshot = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
        if (this.paymentHeader.invoice_type.key === invoiceTypesObj.tuitionFee.key && this.paymentHeader.school_fee_type.key === enrollPrograms.institutes.key) {
          tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
        } else {
          tuitionListing = snapshot.map(m => {
            const { course, amount } = m;
            const { code } = course.schedule_subject.subject;
            const { payment_type, scheduleType } = course
            const fee = MappingService.getFeeRef(setting, code, course.fee, payment_type, scheduleType);
            const price = MappingService.getForeignerFee(student, setting, fee);
            const grandTotal = price * course.schedule_subject.credit;
            const isResolve = amount !== grandTotal ? true : false;
            const resolveFeeRef = MappingService.feeObj(fee);
            return {
              ...m,
              resolve: isResolve,
              amount: grandTotal,
              grand_total: grandTotal,
              price: price,
              total: grandTotal,
              resolveFeeRef: resolveFeeRef
            };
          })
        }
      }
      else {
        tuitionListing = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key) : [];
      }

      const tuitionDetail = tuitionListing.find(m => !m.isHeader && m.invoice_type.key === invoiceTypesObj.tuitionFee.key)
              let isExitExamCourse = false
              if (tuitionDetail && tuitionDetail.course && (tuitionDetail.course.key === "5MXGDfFa3Irno1LveUBE" || tuitionDetail.course.key === "z2W0u01TZ1IdlmrVuVSn")) {
                isExitExamCourse = true
              }

      this.paymentTuitionFee = tuitionListing.length > 0 ? tuitionListing : null;
      this.paymentScholarshipPenalty = docs.length > 0 ? docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.scholarshipPenalty.key) : null;
      this.paymentMiscellaneous = docs.length > 0 ? docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key || m.invoice_type.key === invoiceTypesObj.abcCourse.key)) : null;

      let { prepaid, installment } = student;
      if (prepaid && prepaid > 0) {
        this.totalPrepaid = ConvertService.toNumber(prepaid);
      }
      if (installment && installment > 0) {
        this.totalInstallment = ConvertService.toNumber(installment);
      }
      if (this.paymentHeader) {
        const { invoice_type } = this.paymentHeader;
        this.scholarshipRef = null;
        this.totalScholarship = null;
        this.totalLoan = null;
        if (invoice_type.key === invoiceTypesObj.tuitionFee.key) {
          //=====HAS SCHOLARSHIP FEE UNPAID
          this.scholarshipRef = this.scholarship;
          if (this.paymentHeader) {
            const { scholarshipRef } = this.paymentHeader;
            if (scholarshipRef && scholarshipRef.key === "unpaid") {
              this.scholarshipRef = scholarshipRef;
            }
          }
          if (this.scholarshipRef && this.paymentTuitionFee && this.paymentTuitionFee.length > 0) {
            const totalPriceFee = MappingService.sum(this.paymentTuitionFee, "amount");
            let { totalLoan, totalScholarship } = MappingService.calScholarship(totalPriceFee, this.scholarshipRef)
            this.totalScholarship = totalScholarship;
            this.totalLoan = totalLoan;
          }
        }
      }
      let totalDeduction = ConvertService.toNumber(this.totalScholarship) + ConvertService.toNumber(this.totalLoan);

      //=====HAS PENALTY FEE
      // if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
      //   let { penalty, penaltyRef } = this.paymentHeader;
      //   this.totalPenalty = penalty;
      //   this.penaltyRef = penaltyRef;
      // }

      const { issue_term } = this.paymentHeader;
      const termDoc = await this.ds.academicTermDocRef().doc(issue_term.key).get().toPromise();
      let currentTermData = MappingService.pushToObject(termDoc);

      let penaltyFee = null
      if (currentTermData && currentTermData.penalty_first_date_key && currentTermData.penalty_second_date_key && !isExitExamCourse) {
        const todayKey = ConvertService.toDateKey(new Date);
        const { first_penalty_fee, second_penalty_fee, academic_penalty_fee } = setting;

        if (admission && admission.admission_type && admission.admission_type.key === 2) {

          if ((todayKey > currentTermData.penalty_first_date_key) && (todayKey <= currentTermData.penalty_second_date_key)) {
            penaltyFee = first_penalty_fee;
          }

          if (todayKey > currentTermData.penalty_second_date_key) {
            penaltyFee = second_penalty_fee;
          }
        }

        if (admission && admission.admission_type && admission.admission_type.key === 3) {
          const isNewStu = admission && admission.term && admission.term.key === issue_term.key ? true : false;
          if (todayKey > currentTermData.penalty_first_date_key && !isNewStu) {
            penaltyFee = academic_penalty_fee;
          }
        }

        if (penaltyFee && !isWaivePenalty) {
          this.penaltyRef = penaltyFee;
          this.totalPenalty = penaltyFee.price;
        }

        if (isWaivePenalty) {

          this.penaltyRef = null;
          this.totalPenalty = 0;
          penaltyFee = null
        }
      }

      //=====HAS DISCOUNT FEE
      if (this.paymentHeader && this.paymentHeader.discount && this.paymentHeader.discount > 0) {
        let { discount } = this.paymentHeader;
        this.totalDiscount = discount;

        const { EARLY_PAYMENT_DISCOUNT } = setting
        const { school_fee_type, issue_term } = this.paymentHeader

        const { startDateKey } = currentTermData
        let isNewStu = this.paymentDetail.filter(m => !m.isHeader && m.invoice_type && m.invoice_type.key === 2 && m.isFreshmen).length > 0 ? true : false
        if (!isNewStu && school_fee_type && school_fee_type.key === 1) {
          const admissionData = admission
          if (admissionData && admissionData.term && admissionData.term.key === issue_term.key) {
            isNewStu = true
          }
        }

        const isHaveScholarship = this.scholarship && (this.scholarship.loan + this.scholarship.percentage) > 0 ? true : false
        const isHaveEarlyDiscount = student[`EarlyDiscount_${issue_term.key}`]
        const isHaveDiscount = school_fee_type && school_fee_type.key === 1 && !isHaveScholarship && !isNewStu && !isHaveEarlyDiscount ? true : false
        const totalEarlyDiscount = isHaveDiscount && startDateKey >= ConvertService.dateKey() ? EARLY_PAYMENT_DISCOUNT || 5 : 0

        if ((!this.paymentHeader.discount_date_key || (this.paymentHeader.discount_date_key !== ConvertService.dateKey())) && isHaveDiscount) {
          this.totalDiscount = totalEarlyDiscount;
        }

      }

      //=====HAS SCHOLARSHIP PENALTY FEE
      if (this.paymentScholarshipPenalty) {
        this.totalScholarshipPenalty = MappingService.sum(this.paymentScholarshipPenalty, 'amount');
      }

      //=====HAS MISCELLANEOUS FEE
      if (this.paymentMiscellaneous) {
        this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
      }

      //=====HAS TUITIONS FEE
      if (this.paymentTuitionFee) {
        this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
      }

      //===== IS SENIOR PROJECT
      let isSenior = false;
      if (this.paymentTuitionFee) {
        isSenior = this.paymentTuitionFee.filter(m => m.course && m.course.isSenior).length > 0;
        if (isSenior) {
          totalDeduction = 0;
          this.scholarshipRef = null;
          this.totalScholarship = null;
          this.totalLoan = null;
        }
      }

      this.gradePrice = ConvertService.toNumber(this.totalPrice) - totalDeduction - ConvertService.toNumber(this.totalPrepaid) - ConvertService.toNumber(this.totalDiscount);
      this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalScholarshipPenalty) + ConvertService.toNumber(this.totalPenalty);

      if (this.paymentHeader) {

        const batch = this.ds.batch();
        const studentRef = this.ds.studentFireDocRef().doc(studentKey);
        const wingInvoiceRef = this.ds.wingInvoiceFireRef().doc(this.paymentHeader.key)

        const { EARLY_PAYMENT_DISCOUNT } = setting
        const { school_fee_type, issue_term } = this.paymentHeader

        const { startDateKey } = currentTermData
        let isNewStu = this.paymentDetail.filter(m => !m.isHeader && m.invoice_type && m.invoice_type.key === 2 && m.isFreshmen).length > 0 ? true : false
        if (!isNewStu && school_fee_type && school_fee_type.key === 1) {
          const admissionData = admission
          if (admissionData && admissionData.term && admissionData.term.key === issue_term.key) {
            isNewStu = true
          }
        }

        const isHaveScholarship = this.scholarship && (this.scholarship.loan + this.scholarship.percentage) > 0 ? true : false
        const isHaveEarlyDiscount = student[`EarlyDiscount_${issue_term.key}`]
        const isHaveDiscount = school_fee_type && school_fee_type.key === 1 && !isHaveScholarship && !isNewStu && !isHaveEarlyDiscount ? true : false
        const totalEarlyDiscount = isHaveDiscount && startDateKey >= ConvertService.dateKey() ? EARLY_PAYMENT_DISCOUNT || 5 : 0

        if ((!this.paymentHeader.discount_date_key || (this.paymentHeader.discount_date_key !== ConvertService.dateKey())) && isHaveDiscount) {
          batch.update(studentRef.collection("invoices").doc(this.paymentHeader.key), {
            discount: totalEarlyDiscount,
            discountEarly: true,
            discountEarly_by: "student",
          })
        }

        if (penaltyFee) {
          batch.update(studentRef.collection("invoices").doc(this.paymentHeader.key), {
            penalty: this.totalPenalty,
            penaltyRef: this.penaltyRef,
          })
        }

        if (isWaivePenalty) {
          batch.update(studentRef.collection("invoices").doc(this.paymentHeader.key), {
            penalty: 0,
            penaltyRef: null,
          })
        }

        batch.set(wingInvoiceRef, {
          ...this.paymentHeader,
          scholarshipRef: ConvertService.toNull(this.scholarshipRef),
          scholarship: ConvertService.toNull(this.totalScholarship),
          loan: ConvertService.toNull(this.totalLoan),
          prepaid: ConvertService.toNull(this.totalPrepaid),
          penaltyRef: ConvertService.toNull(this.penaltyRef),
          penalty: ConvertService.toNull(this.totalPenalty),
          studentKey: studentKey,
          admissionKey: admission.key,
          invoiceKey: invoiceKey,
          amount: this.grandTotal,
        })

        batch.commit().then(() => {
          this.loading = false;
          callback(this.grandTotal)
        }).catch(error => {
          this.loading = false;
          callback(null)
        })
      }
    })

  }

  @action
  async fetchOnlineTesting(studentKey: string, invoiceKey: string, callback) {
    const invoiceDoc = await this.ds.studentDocument(studentKey).collection("invoices").doc(invoiceKey).get().toPromise();
    const invoiceData = MappingService.pushToObject(invoiceDoc);
    const wingInvoiceRef = this.ds.wingInvoiceFireRef().doc(invoiceKey);
    const batch = this.ds.batch();
    batch.set(wingInvoiceRef, {
      ...invoiceData,
      studentKey: studentKey,
      invoiceKey: invoiceKey,
    })

    batch.commit().then(() => {
      this.loading = false;
      callback(invoiceData.amount)
    }).catch(error => {
      this.loading = false;
      callback(null)
    })
  }

  @action
  fetchScholarShiftPenalty(studentKey: string, callback) {
    this.loading = true;
    this.ds.studentRef(studentKey).collection("scholarship_penalty", ref => ref
      .where("isPaid", "==", false))
      .valueChanges().subscribe(docs => {
        this.scholarshipPenalty = docs;
        this.loading = false;
        callback(docs);
      })
  }

  @action
  fetchEnrollment(admissionKey, student: any, scholarship: any, lateFee: any, activeTerm: any, callback) {
    this.loading = true;
    this.invoiceHeader = null;
    this.enrollIncluded = null;
    this.enrollExcluded = null;
    this.enroll = null;
    this.totalScholarship = null;
    this.totalLoan = null;
    this.totalPrepaid = null;
    this.totalInstallment = null;
    this.totalPrice = null;
    this.gradePrice = null;
    this.grandTotal = null;
    this.totalLate = null;
    this.invoice = null;
    this.invoiceHeader = null;
    this.totalTuitionFee = null;
    this.otherFee = null;
    this.scholarshipRef = null;
    this.penaltyRef = null;
    this.ds.studentEnrollmentDocsRef(student.key, admissionKey).valueChanges().subscribe(docs => {
      this.enrollExcluded = MappingService.filter(docs, "included", false);
      this.enrollIncluded = MappingService.filter(docs, "included", true);
      this.enroll = this.enrollIncluded;
      const mapAmount = this.enrollIncluded.map(m => m.enrollment_type.key === "ACADEMIC" ? ({ amount: m.fee.price * m.subject.credit }) : ({ amount: m.fee.price }))
      let { prepaid, installment } = student;

      //=====Has enrollments
      if (this.enrollIncluded.length > 0) {
        this.totalPrice = MappingService.sum(mapAmount, 'amount');
        this.scholarshipRef = scholarship;
        if (this.scholarshipRef) {
          let { totalLoan, totalScholarship } = MappingService.calScholarship(this.totalPrice, scholarship)
          this.totalScholarship = totalScholarship;
          this.totalLoan = totalLoan;
        }
        if (prepaid && prepaid > 0) {
          this.totalPrepaid = ConvertService.toNumber(prepaid);
        }
        if (installment && installment > 0) {
          this.totalInstallment = ConvertService.toNumber(installment);
        }
      }

      //=====Has Scholarship Penalty
      if (this.scholarshipPenalty.length > 0) {
        let totalScholarShipPenalty = MappingService.sum(this.scholarshipPenalty, 'totalScholarshipPenalty');
        this.totalScholarShipPenalty = ConvertService.toNull(totalScholarShipPenalty);
      }

      const { endPaymentKey } = activeTerm;
      const todayKey = ConvertService.toDateKey(new Date);
      if (todayKey > endPaymentKey) {
        this.penaltyRef = lateFee.price;
        this.totalPenalty = lateFee.price;
        this.totalLate = todayKey - endPaymentKey;
      }


      const totalDeductions = ConvertService.toNumber(this.totalScholarship) + ConvertService.toNumber(this.totalLoan) + ConvertService.toNumber(this.totalPrepaid)
      if (this.totalScholarShipPenalty > 0) this.gradePrice = ConvertService.toNumber(this.totalScholarShipPenalty)
      else this.gradePrice = ConvertService.toNumber(this.totalPrice) + ConvertService.toNumber(this.totalPenalty);
      this.grandTotal = this.gradePrice - totalDeductions;

      this.empty = docs.length === 0;
      this.loading = false;
      callback(this.enroll)
    })
  }

  @action
  schoolPayment(enrollment: Array<any>, scholarshipPenalty: Array<any>, totalScholarship: any, totalLoan: any, totalPrepaid: any, grandTotal: any, gradePrice: any, student: any, term: any, callback) {
    this.process = true;
    const { puc_id } = student;
    // this.ds.updateInvoiceNoAndPucID(puc_id, (success, response) => {
    //   if (success) {
    const batch = this.ds.batch();
    const invoiceRef = this.ds.invoiceFireRef();
    // const studentRef = this.ds.studentFireDocRef();
    const headerKey = this.ds.createId();
    // const invoiceNo = ConvertService.generate_testing_invoiceNo(response);
    const invoiceNo = null;

    // const { create_by, payment_term, shift, cashIn, note } = item;
    // RECEIVE ITEM
    const date = new Date();
    const create_by = null;
    const campus = null;
    const shift = null;
    const cashIn = null;
    const note = null;
    const payment_term = term;

    let courseProgram = null;
    let invoiceType = null;
    let displayProgram = null;
    let displayLevel = null;
    let displayShift = null;
    let schoolFeeType = null;

    if (enrollment.length > 0) {
      invoiceType = invoiceTypesObj.tuitionFee;
      courseProgram = enrollment[0].program;
    }

    if (enrollment.length > 0) {
      let { enrollment_type, program, subject, study_session, shift } = enrollment[0];
      if (enrollment_type.key === 'ACADEMIC') {
        displayProgram = program.type.text;
        displayLevel = program.name;
        // displayShift = shift.shift.name;
        displayShift = null;
        schoolFeeType = enrollPrograms.academic;
      } else if (enrollment_type.key === 'INSTITUTE') {
        displayProgram = program.category.name;
        displayLevel = subject.program.shortName + subject.name;
        displayShift = study_session.name;
        schoolFeeType = enrollPrograms.institutes;
      }
    }

    if (scholarshipPenalty.length > 0) {
      displayProgram = "Scholarship Penalty";
      displayLevel = null;
      displayShift = null;
      schoolFeeType = enrollPrograms.academic;
      invoiceType = invoiceTypesObj.scholarshipPenalty
    }

    const invoiceHeader: IInvoice = {
      key: headerKey,
      display_program: displayProgram,
      display_level: displayLevel,
      display_shift: displayShift,
      create_date: date,
      create_date_key: ConvertService.toDateKey(date),
      create_by: create_by,
      issue_by: create_by,
      issue_date: date,
      invoice_no: invoiceNo,
      invoice_type: invoiceType,
      school_fee_type: schoolFeeType,
      student: MappingService.studentObj(student),
      verify_by: create_by,
      verify_date: date,
      page_key: ConvertService.pageKey(),
      verify_date_key: ConvertService.toDateKey(date),
      invoice_date: date,
      invoice_date_key: ConvertService.toDateKey(date),
      course: null,
      isPaid: paymentStatus.unpaid,
      isVoid: false,
      program: courseProgram,
      byAdmission: false,
      price: gradePrice,
      amount: grandTotal,
      issue_term: payment_term,
      payment_term: payment_term,
      headerRef: headerKey,
      isHeader: true,
      enroll_status: EnrollStatus.assign,
      isEnrollVerify: false,
      campus: MappingService.toNull(campus),
      schoolSession: null,
      description: null,
      penaltyRef: ConvertService.toNull(this.penaltyRef),
      penalty: ConvertService.toNull(this.totalPenalty),
      scholarshipRef: ConvertService.toNull(this.scholarshipRef),
      scholarship: ConvertService.toNull(totalScholarship),
      loan: ConvertService.toNull(totalLoan),
      prepaidRef: ConvertService.toNull(student.prepaidRef),
      prepaid: ConvertService.toNull(totalPrepaid),
      //===== Receive Payment
      // received_by: create_by,
      // received_date: date,
      // received_date_key: ConvertService.toDateKey(date),
      // received_campus: MappingService.toNull(campus),
      // dailyShift: shift,
      // caseIn: cashIn,
      // note: note,
      // payment_type: paymentType.wingOnline,
    }

    batch.set(invoiceRef.doc(invoiceHeader.key), invoiceHeader);

    if (scholarshipPenalty.length > 0) {
      scholarshipPenalty.forEach(draft => {
        draft.invoice_no = invoiceNo;
        draft.payment_term = payment_term;
        draft.headerRef = headerKey;
        draft.isHeader = false;
        draft.isPaid = true;
        //===== Receive Payment
        draft.received_by = create_by;
        draft.received_date = date;
        draft.received_date_key = ConvertService.toDateKey(date);
        draft.received_campus = null;
        draft.dailyShift = shift;
        draft.caseIn = cashIn;
        draft.payment_type = paymentType.cash;
        draft.student = MappingService.studentObj(student);
        draft.invoiceType = invoiceTypesObj.scholarshipPenalty,

          batch.update(this.ds.studentFireDocRef().doc(student.key).collection("scholarship_penalty").doc(draft.key), {
            ...draft
          });
      })
    }

    // const prepaidRef = this.ds.prepaidFireRef();

    // if (enrollment.length > 0) {

    //   // if (totalPrepaid > 0) {
    //   //   let prepaidData = {
    //   //     key: this.ds.createId(),
    //   //     status: status[0],
    //   //     create_date: new Date(),
    //   //     create_by: MappingService.userObj(create_by),
    //   //     page_key: ConvertService.pageKey(),
    //   //     student: MappingService.studentObj(student),
    //   //     invoiceKey: headerKey,
    //   //     price: totalPrepaid * -1,
    //   //     received_by: MappingService.userObj(create_by),
    //   //     received_date: new Date(),
    //   //     received_date_key: ConvertService.dateKey(),
    //   //     term: MappingService.termObj(payment_term),
    //   //     campus: MappingService.campusObj(campus),
    //   //     note: null,
    //   //   }
    //   //   let prepaidBalance = ConvertService.toNumber(student.prepaid) - ConvertService.toNumber(totalPrepaid);
    //   //   batch.update(studentRef.doc(student.key), {
    //   //     prepaid: prepaidBalance > 0 ? prepaidBalance : null,
    //   //     prepaidRef: prepaidData,
    //   //   });

    //   //   batch.set(prepaidRef.doc(prepaidData.key), prepaidData);
    //   // }

    //   enrollment.forEach(enroll => {
    //     const coursePrice = enroll.enrollment_type.key === 'ACADEMIC' ? enroll.fee.price * enroll.schedule.schedule_subject.credit : enroll.fee.price;
    //     const enrollLength = enrollment.length;
    //     const enrollScholarship = ConvertService.toNumber(totalScholarship / enrollLength);
    //     const enrollLoan = ConvertService.toNumber(totalLoan / enrollLength);
    //     const enrollAmount = coursePrice - (enrollScholarship + enrollLoan);

    //     const invoiceDetail: IInvoice = {
    //       key: this.ds.createId(),
    //       display_program: displayProgram,
    //       display_level: displayLevel,
    //       display_shift: displayShift,
    //       create_date: date,
    //       create_date_key: ConvertService.toDateKey(date),
    //       create_by: enroll.create_by,
    //       issue_by: create_by,
    //       issue_date: date,
    //       invoice_no: invoiceNo,
    //       invoice_type: invoiceTypesObj.tuitionFee,
    //       school_fee_type: schoolFeeType,
    //       student: MappingService.studentObj(student),
    //       verify_by: create_by,
    //       verify_date: date,
    //       page_key: ConvertService.pageKey(),
    //       verify_date_key: ConvertService.toDateKey(date),
    //       invoice_date: date,
    //       invoice_date_key: ConvertService.toDateKey(date),
    //       course: enroll.schedule,
    //       enrollmentKey: enroll.key,
    //       isPaid: paymentStatus.unpaid,
    //       isVoid: false,
    //       program: enroll.program,
    //       byAdmission: false,
    //       price: coursePrice,
    //       amount: enrollAmount,
    //       issue_term: enroll.term,
    //       payment_term: payment_term,
    //       headerRef: headerKey,
    //       isHeader: false,
    //       enroll_status: EnrollStatus.assign,
    //       isEnrollVerify: false,
    //       campus: MappingService.toNull(campus),
    //       schoolSession: null,
    //       description: displayLevel,

    //       penaltyRef: ConvertService.toNull(this.penaltyRef),
    //       penalty: ConvertService.toNull(this.totalPenalty),
    //       scholarshipRef: ConvertService.toNull(this.scholarshipRef),
    //       scholarship: ConvertService.toNull(enrollScholarship),
    //       loan: ConvertService.toNull(enrollLoan),
    //       prepaidRef: ConvertService.toNull(student.prepaidRef),
    //       prepaid: ConvertService.toNull(totalPrepaid),
    //       //===== Receive Payment
    //       // received_by: create_by,
    //       // received_date: date,
    //       // received_date_key: ConvertService.toDateKey(date),
    //       // received_campus: MappingService.toNull(campus),
    //       // dailyShift: shift,
    //       // caseIn: cashIn,
    //       // payment_type: paymentType.wingOnline,
    //     }

    //     batch.set(invoiceRef.doc(invoiceDetail.key), invoiceDetail);

    //     // const enrollData = {
    //     //   student: MappingService.studentObj(student),
    //     //   scholarshipRef: this.scholarshipRef,
    //     //   scholarship: enrollScholarship,
    //     //   loan: enrollLoan,
    //     //   prepaidRef: ConvertService.toNull(student.prepaidRef),
    //     //   prepaid: totalPrepaid,
    //     //   penaltyRef: ConvertService.toNull(this.penaltyRef),
    //     //   penalty: ConvertService.toNull(this.totalPenalty),
    //     //   grand_total: enrollAmount,
    //     //   invoiceRef: headerKey,
    //     //   received_by: create_by,
    //     //   received_date: date,
    //     //   received_date_key: ConvertService.toDateKey(date),
    //     //   received_campus: MappingService.toNull(create_by.campus),
    //     //   payment_term: payment_term,
    //     // }

    //     // batch.update(studentRef.doc(student.key).collection("enrollment").doc(enroll.key), {
    //     //   ...enroll,
    //     //   ...enrollData,
    //     //   isPaid: true
    //     // });


    //     // if (enroll.enrollment_type.key === 'INSTITUTE' && enroll.isFreshmen) {
    //     //   batch.set(instituteEnrollmentRef.doc(enroll.key), {
    //     //     ...enroll,
    //     //     ...enrollData,
    //     //     isPaid: true
    //     //   });

    //     // } else {
    //     //   batch.update(academicTermRef.doc(payment_term.key).collection("enrollment").doc(enroll.key), {
    //     //     ...enroll,
    //     //     ...enrollData,
    //     //     isPaid: true
    //     //   });
    //     // }

    //   })
    // }

    batch.commit().then(() => {
      this.process = false;
      callback(true, headerKey);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
    //   }
    // })
  }

}

