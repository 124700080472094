import { Observable } from 'rxjs/Observable';
import { DataService } from '../services/data.service';
// import { FireStorageService } from '../services/fire-storage.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { Environment } from './environment.store';
import * as firebase from 'firebase';
import { IStudent } from '../interfaces/enrollment';
import { ConvertService } from '../services/convert.service';
import { enrollPrograms, invoiceTypesObj, paymentStatus, recordStatus } from '../dummy/status';
import { MappingService, pushToObject } from '../services/mapping.service';
import { IInvoice } from '../interfaces/invoice';
import { createImportSpecifier } from 'typescript';
import { toDate } from '@angular/common/src/i18n/format_date';
import { Auth } from './auth.store';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class Scholarship {
  @observable data: any = [];
  @observable loading = false;
  @observable academic: any = null;
  @observable config = null;
  @observable START_UPLOAD: boolean = false;
  uploadPercent: any;

  constructor(private ds: DataService, private auth: AuthService,

  ) {
  }

  @action
  async getScholarshipExamData() {
    const docs = await this.ds.sysSetting().get().toPromise();
    return pushToObject(docs);
  }

  @action
  async getScholarshipSerialId(callback: (serial_id: string) => void) {
    const docs = await this.ds.sysSetting().get().toPromise();
    this.config = pushToObject(docs);
    const serial_number = this.config.scholarship_number || 0;
    const serialID = serial_number + 1;
    const serial_id = 99 + serialID.toString();
    this.ds.sysSetting().set({
      scholarship_number: firebase.firestore.FieldValue.increment(1)
    }, { merge: true }).then(() => {
      callback(serial_id)
    })
    console.log('serial_id', serial_id)
  }


  @action
  registerScholarshipExam(item, callback) {
    this.loading = true;
    this.getScholarshipSerialId(async serial_id => {
      this.auth.authRef().auth.createUserWithEmailAndPassword(`${serial_id}@puc.edu.kh`, "016555392").then(async (user) => {
        const studentKey = user.user.uid;
        const batch = this.ds.batch();
        const { lastName, firstName, gender, file, dob, phone, previousEnglish, shiftOfExam,
          previousTest, previousTestNote, scholarshipDate, yearOfBacII, } = item
        const last_name = lastName.trim().toUpperCase();
        const first_name = firstName.trim().toUpperCase();
        const full_name = last_name + ' ' + first_name;
        const scholarshipKey = this.ds.createId()
        const display_program = 'Scholarship'
        const date = new Date();
        const date_Key = ConvertService.dateKey()
        const page_key = ConvertService.pageKey()
        const createKey = { key: studentKey }
        const program = this.config.ScholarshipExam

        // const scholarshipData = {
        //   key: scholarshipKey,
        //   created_at: date,
        //   updated_at: date,
        //   first_name: first_name,
        //   last_name: last_name,
        //   full_name: full_name,
        //   gender: gender,
        //   birthDate: dob,
        //   birthDateKey: ConvertService.toDateKey(dob),
        //   scholarshipDate: examDate,
        //   scholarshipDateKey: ConvertService.toDateKey(examDate),
        //   phoneNumber: phone,
        //   previousEnglish: previousEnglish,
        //   previousTestNote: previousTestNote,
        //   yearOfBacII: yearOfBacII,
        //   file: file,
        //   previousTest: previousTest,
        // }
        const studentData: IStudent = {
          key: studentKey,
          unique_code: null,
          first_name,
          last_name,
          khmer_first_name: null,
          khmer_last_name: null,
          full_name,
          address: null,
          gender,
          serial_id,
          puc_id: null,
          recent_test_key: scholarshipKey,
          is_puc_student: false,
          dob,
          dobKey: ConvertService.toDateKey(dob),
          page_key: page_key,
          mobile_phone: phone,
          email: null,
          email_address: null,
          pob: null,
          marital_status: null,
          nationality: null,
          home_address: null,
          work_place: null,
          position: null,
          parent_name: null,
          spouse_name: null,
          emergency_person_name: null,
          emergency_relationship: null,
          emergency_address: null,
          emergency_phone: null,

          english_school_name: null,
          english_school_province: null,
          english_school_year: null,
          high_school_name: null,
          high_school_province: null,
          high_school_year: null,
          college_school_name: null,
          college_school_degree: null,
          college_school_major: null,
          college_school_year: null,
          university_school_name: null,
          university_school_degree: null,
          university_school_major: null,
          university_school_year: null,
          graduate_school_name: null,
          graduate_school_degree: null,
          graduate_school_major: null,
          graduate_school_year: null,
          education_type_at_puc: null,
          status: recordStatus.active,
          program_general: null,
          create_date: date,
          create_date_key: date_Key,
          create_by: createKey,
          prepaid: null,
          scholarship: null,
          program_academic: null,
          is_student_PSIS: false,
          is_student_institute: false,
          is_student_academic: false,
          id_file: file,
        };
        const emailStudent = `${serial_id}@puc.edu.kh`;
        const studentAccountData = {
          key: studentKey,
          create_date: date,
          create_by: createKey,
          status: recordStatus.active,
          puc_id: null,
          email: emailStudent,
          fileUrl: null,
          displayName: full_name,
          studentKey,
          phone: phone,
          token: null,
          pinCode: null,
          student: MappingService.studentObj(studentData),
        }
        const environment = await this.ds.englishTerRef().get().toPromise()
        const termConfig = pushToObject(environment)
        const isPaid = paymentStatus.unpaid
        const unpaidTest = this.config.unpaidScholarShip
        const testingFee = this.config.testing_fee.price || 2.5;
        const testingData = {
          key: scholarshipKey,
          unique_code: null,
          serial_id,
          first_name: first_name,
          last_name: last_name,
          full_name: full_name,
          display_name: full_name,
          khmer_first_name: null,
          khmer_last_name: null,
          address: null,
          gender: gender,
          dob: dob,
          dobKey: ConvertService.toDateKey(dob),
          phone: phone,
          previousEnglish: previousEnglish,
          shiftOfExam: shiftOfExam,
          previousTestNote: previousTestNote,
          yearOfBacII: yearOfBacII,
          file: file,
          previousTest: previousTest,
          scholarshipDate: scholarshipDate,
          scholarshipDateKey: ConvertService.toDateKey(scholarshipDate.toDate()),
          puc_id: null,
          page_key,
          mobile_phone: phone,
          email_address: null,
          institute: null,
          test_type: program,
          study_session: null,
          target_term: MappingService.termObj(termConfig),
          target_campus: null,
          admission_date: date,
          admission_date_key: date_Key,
          isAssignProgram: false,
          note: null,
          status: recordStatus.active,
          testingStatus: recordStatus.active,
          isPaid: paymentStatus.unpaid,
          isPaidTest: false,
          isTakeTest: false,
          onlineTesting: true,
          create_date: date,
          create_date_key: date_Key,
          create_by: createKey,
          branch: null,
          admission_term: MappingService.termObj(termConfig),
          student_id: null,
          student_puc_profile: null,
          admission: null,
          config: null,
          expired_fee: null,
          expired_fee_key: null,
          expired_test: null,
          expired_test_key: null,
          testing_result: null,
          isReTakeTest: false, //
          student: MappingService.studentObj(studentData),
          isScholarship: true,
        }
        const studentRef = this.ds.studentFireDocRef().doc(studentKey);
        const studentAccount = this.ds.studentAccountFireDocRef().doc(studentKey);
        const testingRef = this.ds.testingFireRef().doc(testingData.key);
        const studentInvoiceRef = this.ds.studentFireDocRef().doc(studentData.key).collection("invoices");
        const invoiceNo = null;
        const invoiceHeader = {
          key: scholarshipKey,
          display_program,
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: date_Key,
          create_by: createKey,
          issue_by: createKey,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.scholarship_exam,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: createKey,
          verify_date: date,
          page_key,
          verify_date_key: date_Key,
          invoice_date: date,
          invoice_date_key: date_Key,
          course: {
            key: program.key,
            text: program.shortName,
            name: program.name,
          },
          isPaid,
          isVoid: false,
          program: invoiceTypesObj.scholarship_exam,
          byAdmission: false,
          price: testingFee || 0,
          amount: testingFee || 0,
          issue_term: MappingService.termObj(termConfig),
          payment_term: null,
          headerRef: scholarshipKey,
          isHeader: true,
          isEnrollVerify: true,
          campus: null,
          schoolSession: null,
          description: null,
          penalty: null,
          prepaid: null,
          scholarship: null,
          scholarshipRef: null,
          prepaidRef: null,
          penaltyRef: null,
          isScholarship: true,
        }
        const invoiceDetail = {
          key: this.ds.createId(),
          display_program,
          display_level: null,
          display_shift: null,
          create_date: date,
          create_date_key: date_Key,
          create_by: createKey,
          issue_by: createKey,
          issue_date: date,
          invoice_no: invoiceNo,
          invoice_type: invoiceTypesObj.scholarship_exam,
          school_fee_type: enrollPrograms.institutes,
          student: MappingService.studentObj(studentData),
          verify_by: createKey,
          verify_date: date,
          page_key,
          verify_date_key: date_Key,
          invoice_date: date,
          invoice_date_key: date_Key,
          course: null,
          isPaid,
          isVoid: false,
          program,
          byAdmission: false,
          price: testingFee || 0,
          amount: testingFee || 0,
          issue_term: MappingService.termObj(termConfig),
          payment_term: null,
          headerRef: scholarshipKey,
          isHeader: false,
          isEnrollVerify: true,
          campus: null,
          schoolSession: null,
          description: null,
          scholarship: null,
          scholarshipRef: null,
          isScholarship: true,
        }
        const profile_register = {
          full_name: full_name,
          serial_id: serial_id,
          text_type: invoiceTypesObj.scholarship_exam,
          exam_date: scholarshipDate,
          email: emailStudent,
          // password: '016555392'
        }

        // NEW INVOICE NUMBER
        if (!unpaidTest) batch.set(studentInvoiceRef.doc(invoiceHeader.key), invoiceHeader, { merge: true });
        if (!unpaidTest) batch.set(studentInvoiceRef.doc(invoiceDetail.key), invoiceDetail, { merge: true });
        batch.set(studentRef, studentData, { merge: true });
        batch.set(testingRef, testingData, { merge: true });
        batch.set(studentAccount, studentAccountData, { merge: true });

        batch.commit().then(() => {
          this.loading = false;
          callback(true, profile_register);
        }).catch(error => {
          this.loading = false;
          callback(false, error);
        })
      }).catch(error => { })

    })


  }

}
