import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'holidayByYear'
})
export class HolidayByYearPipe implements PipeTransform {

  transform(value: Array<any>, args?: any): any {
    return value.filter(m=>m.year===args);
  }

}
