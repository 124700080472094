import { Student } from 'src/app/stores/student.store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  id: string;
  expansions = {
    explore: false,
    people: false
  };
  toggleSidebar = true;
  disabled = true;
  programId = '';
  isMobile: boolean;
  observerSubscrption: Subscription;
  constructor(breakpointObserver: BreakpointObserver,
    public student: Student,
    ) {
    this.observerSubscrption = breakpointObserver.observe([Breakpoints.Web]).subscribe((result: BreakpointState) => {
      this.isMobile = !result.matches;
    });
  }

  ngOnInit() {
    const body = document.getElementsByClassName('page-wrapper')[0];
    if (this.isMobile) {
      body.classList.remove('toggled-sidebar');
    } else {
      body.classList.add('toggled-sidebar');
    }
  }
  ngOnDestroy(): void {
    this.observerSubscrption.unsubscribe();
  }
  togglesidebar() {
    const body = document.getElementsByClassName('page-wrapper')[0];
    body.classList.toggle('toggled-sidebar');
    this.toggleSidebar = !this.toggleSidebar;
    // this.localStorages
    //   .setItem("toggleSidebar", this.toggleSidebar)
    //   .subscribe(() => { });
  }
  onOpenClosed(e, isOpen: boolean) {
    switch (e) {
      case 0:
        this.expansions.explore = isOpen;
        break;
      case 1:
        this.expansions.people = isOpen;
        break;
      default:
        break;
    }
  }
}
