import { Component, OnInit, Input } from '@angular/core';
import { Schedule } from 'src/app/stores/schedule.store';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss']
})
export class StudentScheduleComponent implements OnInit {
  @Input() schedule:any;
  @Input() termKey:any;
  @Input() css:any;
  @Input() canEnroll:any;
 

  public item: any;
  constructor(private store: Schedule) { }

  async ngOnInit() {
    const { schedule, program_type } = this.schedule;
    if (program_type && program_type.key === 'institute') return;
    // const scheduleDoc=await this.store.fetchScheduleDocument(this.termKey,schedule.key).get().toPromise();
    // this.item=MappingService.pushToObject(scheduleDoc);
  }

}
