export const status = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];

export const enrollStatus = [
  { key: 1, text: "Pending" },
  { key: 2, text: "Add" },
  { key: 3, text: "Drop" }
];

export const enrollStatusObj = {
  pending:{ key: 1, text: "Pending" },
  add:{ key: 2, text: "Add" },
  drop:{ key: 3, text: "Drop" }
};

export const enrollPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'ABC Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const enrollmentTypes = {
  PSIS: { key: "PSIS", text: "PSIS Program" },
  institute: { key: "INSTITUTE", text: "Institute program" },
  academic: { key: "ACADEMIC", text: "Academic Program" }
};

export const invoiceTypesObj = {
  testing: { key: 1, text: "Testing" },
  tuitionFee: { key: 2, text: "Tuition Fee" },
  shortCourse: { key: 3, text: "Short Course" },
  abcCourse: { key: 4, text: "Academic Bridging Course" },
  spel: { key: 5, text: "SPEL" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  scholarship_exam:{ key: 8, text: "Scholarship Exam" },
};

export const paymentStatus = {
  paid: { key: 1, text: "Paid" },
  unpaid: { key: 2, text: "Unpaid" },
  prepaid: { key: 3, text: "Prepaid" },
  void: { key: 4, text: "Void" },
};

export const EnrollStatus = {
  none: { key: 1, text: "None" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Dropped" },
  assign: { key: 5, text: "Assign" }
};

export const preEnrollStatus = {
  pending: { key: 1, text: "Pending" },
  approved: { key: 2, text: "Approved" },
  rejected: { key: 3, text: "Rejected" },
};

export const paymentType = {
  cash: { key: 1, text: 'Cash' },
  wingOnline: { key: 2, text: 'Wing Online' },
  wingMerchant: { key: 3, text: 'Wing Merchant' },
  ePayment: { key: 4, text: 'Wing E-Payment' },
}

export const recordStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
}

export const Genders = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
];



export const previousTestPObj = [
  { key: 1, text: 'Yes' },
  { key: 2, text: 'No' },
  { key: 3, text: 'Other' },

];

