import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validSchedule'
})
export class ValidSchedulePipe implements PipeTransform {

  transform(value: Array<any>, args?: any): any {
    return null;
  }

}
