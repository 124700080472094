import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { DeviceDetectorService } from "ngx-device-detector";
import { paymentStatus } from "src/app/dummy/status";


@Component({
  selector: 'app-wing-success',
  templateUrl: './wing-success.component.html',
  styleUrls: ['./wing-success.component.scss']
})
export class WingSuccessComponent implements OnInit {

  isComplete: boolean;
  process = true;
  booking = null;
  isDesktop= false;
  
  constructor(
    public route: ActivatedRoute,
    private readonly afs: AngularFirestore,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.isDesktop = this.deviceService.isDesktop();

    this.route.params.forEach(param => {
      const id = param["id"]; 
      this.afs.collection("invoices").doc(id).valueChanges().subscribe(doc => {
        if(doc){
          this.booking = doc
          if(this.booking.isPaid.key === paymentStatus.paid.key){
            this.process = false;
            this.isComplete = true;
          }else{
            this.process = false;
            this.isComplete = false;
          }
        }else this.process = false;
      })
    });
  }
}
