
import { Environment } from './../../stores/environment.store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Student } from 'src/app/stores/student.store';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Transcript } from 'src/app/stores/transcript.store';
import { tabs } from 'src/app/dummy/tabs';

@Component({
  selector: 'app-un-official-transcript',
  templateUrl: './un-official-transcript.component.html',
  styleUrls: ['./un-official-transcript.component.scss']
})
export class UnOfficialTranscriptComponent implements OnInit {
  id: string;
  pid: string;
  process = true;
  ismobile = false;
  tabsLink = tabs.transcript;

  icons=[
    "face",
    "adb",
    "cloud",
    "view_carousel",
    "favorite",
    "verified_user",
  ]
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public student: Student,
    public env: Environment,
    public dialog:MatDialog,
    public snackBar:MatSnackBar,
    private ps: PrintService,
    public auth: Auth,
    public store: Transcript,
    public breakpointObserver: BreakpointObserver


  ) {}

  async ngOnInit() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 736px)');
    this.ismobile = isSmallScreen;
    this.process = true;
    this.route.params.forEach(params => {
      this.id = params["id"];
      this.auth.fetchAccountLink(account => {
        if (account) {
          this.student.fetchStudent(account.studentKey);
          this.student.fetchAdmission(account.studentKey,async doc => {
            if (this.id) {
              this.student.getAdmission(this.id, admission => {
                const { admission_type } = this.student.selectedAdmission;
                if (admission_type.key === 3) {
                  this.store.fetchTranscriptUnofficial(this.student.student.key, this.id, 1)
                } else this.router.navigate(["/not-found-account"]);
              });
            } else {
              if (this.student.selectedAdmission) {
                const { admission_type } = this.student.selectedAdmission;
                if (admission_type.key === 3) {
                  this.store.fetchTranscriptUnofficial(this.student.student.key,
                    this.student.selectedAdmission.key, 1);
                } else this.router.navigate(["/not-found-account"]);
              }
            }
          });
        } else {
          this.router.navigate(["/not-found-account"]);
        }
      });
    });
  }

  print() {
    this.ps.print("#print", "a4");
  }
  
}
