import { Component, OnInit, Input } from '@angular/core';
import { Schedule } from 'src/app/stores/schedule.store';

@Component({
  selector: 'app-mat-payment',
  templateUrl: './mat-payment.component.html',
  styleUrls: ['./mat-payment.component.scss']
})
export class MatPaymentComponent implements OnInit {
  @Input() payment:any;
  @Input() termKey:any;

  public item:any;
  constructor(private store:Schedule) { }

  async ngOnInit() {
  }

}
