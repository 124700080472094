import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService } from "../services/mapping.service";
import { Router } from "@angular/router";
import { ConvertService } from '../services/convert.service';


@Injectable()
export class InstituteEnrollment {
  @observable public student = null;
  @observable public selectedAdmission = null;
  @observable public admissions = [];
  @observable public program = null;
  @observable public scholarships = [];
  @observable data = [];
  @observable schedules = [];
  @observable loading = false;
  @observable empty = false;
  @observable public process = false;
  @observable public studentBatch = null;

  constructor(private ds: DataService,
    private router: Router
  ) { }

  async fetchScholarship() {
    this.process = true;
    const { student } = this.selectedAdmission;
    const scholarshipDoc = await this.ds.scholarshipByStudent(student.key).get().toPromise();
    this.scholarships = MappingService.orderBy(MappingService.pushToArray(scholarshipDoc), "page_key");
    this.process = false;
  }

  @action
  async fetchStudent(studentKey, admissionKey, callback) {
    this.loading = true;
    this.selectedAdmission = null;
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    this.student = MappingService.pushToObject(studentDoc);
    const admissionDocs = await this.ds.admissionRef(studentKey).get().toPromise();
    this.admissions = MappingService.orderByDesc(MappingService.pushToArray(admissionDocs), "program.order");
    if (!admissionDocs.empty) {
      const admissionDoc = await this.ds.admissionDocRef(admissionKey).get().toPromise();
      this.selectedAdmission = MappingService.pushToObject(admissionDoc)
      const { program_academic } = this.selectedAdmission;
      const studentAdmissionKey = MappingService.toNull(admissionKey);
      if (program_academic) {
        this.program = program_academic.category;
        const batchDoc = this.student[this.program.key];
        if (batchDoc) {
          this.ds.batchLevelRef().doc(batchDoc.key).valueChanges().subscribe(doc => {
            this.loading = false;
            this.studentBatch = doc;
          })
        }
        else {
          this.loading = false;
          callback(this.selectedAdmission)
        }
      }
      if (!studentAdmissionKey) {
        this.loading = false;
        this.router.navigate([`/institute-and-center/${this.selectedAdmission.key}/${studentKey}/enrollment`]);
      }

    }
    else {
      this.loading = false
      callback(null)
    }
  }

  @action
  async fetchAdmission(admissionKey, studentKey, callback) {
    const admissionDoc = await this.ds.admissionDocRef(admissionKey).get().toPromise();
    if (admissionDoc.exists) {
      this.selectedAdmission = MappingService.pushToObject(admissionDoc)
      const { program_academic } = this.selectedAdmission;
      this.program = program_academic.category;
      this.loading = false;
      callback(this.selectedAdmission);
    }
    else {
      const admissionDocs = await this.ds.admissionRef(studentKey).get().toPromise();
      this.admissions = MappingService.orderByDesc(MappingService.pushToArray(admissionDocs), "program.order");
      if (!admissionDocs.empty) {
        this.selectedAdmission = this.admissions[0];
        this.program = this.selectedAdmission.program_academic.category;
        await this.ds.studentDocument(studentKey).update({
          program_academic: this.selectedAdmission.program_academic
        }).then(() => {
          callback(this.selectedAdmission);
          this.router.navigate([`/institute-and-center/${this.selectedAdmission.key}/${studentKey}/enrollment`]);
        })
      }
      else {
        callback(null)
      }
    }
  }

  @action
  fetchEnrollment(studentKey, admissionKey, termKey) {
    this.loading = true;
    this.ds.studentDocument(studentKey).collection("institute_enrollment", ref => ref
      .where("term.key", "==", termKey)
      .where("program.admissionKey", "==", admissionKey)
      .orderBy("page_key")
    ).valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchStudentSchedule(studentKey: string, term: any) {
    this.loading = true;
    const { institute, key } = term;
    this.ds.studentDocument(studentKey).valueChanges().subscribe(doc => {
      const studentData = doc;
      const batch = studentData[institute.key];
      if (batch) {
        this.ds.batchLevelRef().doc(batch.key).collection("schedules", ref => ref
          .where("term.key", "==", key)).valueChanges().subscribe(docs => {
            this.schedules = docs;
            this.loading = false;
          })
      } else {
        this.schedules = [];
        this.loading = false;
      }
    })


  }

  @action
  async changeStudentBatch(item: any, batchLevel: any, callback) {
    this.process = true;
    const { student } = item;
    const studentInBatchDoc = await this.ds.batchLevelStudentRef(this.studentBatch.key, student.key).get().toPromise();
    if (!studentInBatchDoc.empty) {
      const currentEnrollment = MappingService.pushToArray(studentInBatchDoc)[0];
      const batch = this.ds.batch();
      const batchRef = this.ds.batchLevelFireRef().doc(this.studentBatch.key).collection("students");
      const newBatchRef = this.ds.batchLevelFireRef().doc(batchLevel.key).collection("students");
      const studentRef = this.ds.studentFireS(student.key);
      const { category } = this.selectedAdmission.program_academic;
      batch.set(newBatchRef.doc(currentEnrollment.key), {
        ...currentEnrollment,
        add_batch_by: item.create_by,
        add_batch_date: item.create_date,
        group: MappingService.batchObj(batchLevel),
      });
      batch.update(studentRef, {
        [category.key]: MappingService.batchObj(batchLevel),
        update_by: item.create_by,
        update_date: item.create_date
      })
      batch.delete(batchRef.doc(currentEnrollment.key))
      batch.commit().then(() => {
        this.process = false;
        callback(true, null);
      })
        .catch(error => {
          callback(false, error);
          this.process = false;
        });
    }
    else {
      callback(false, "No batch");
      this.process = false;
    }
  }

  @action
  async margeBatchSchedule(item: any, batchLevel: any, callback) {
    this.process = true;
    const { student } = item;
    const studentInBatchDoc = await this.ds.batchLevelStudentRef(this.studentBatch.key, student.key).get().toPromise();
    if (!studentInBatchDoc.empty) {
      const currentEnrollment = MappingService.pushToArray(studentInBatchDoc)[0];
      const batch = this.ds.batch();
      const batchRef = this.ds.batchLevelFireRef().doc(this.studentBatch.key).collection("students");
      const newBatchRef = this.ds.batchLevelFireRef().doc(batchLevel.key).collection("students");
      const studentRef = this.ds.studentFireS(student.key);
      const { category } = this.selectedAdmission.program_academic;
      batch.set(newBatchRef.doc(currentEnrollment.key), {
        ...currentEnrollment,
        add_batch_by: item.create_by,
        add_batch_date: item.create_date,
        group: MappingService.batchObj(batchLevel),
      });
      batch.update(studentRef, {
        [category.key]: MappingService.batchObj(batchLevel),
        update_by: item.create_by,
        update_date: item.create_date
      })
      batch.delete(batchRef.doc(currentEnrollment.key))
      // batch.commit().then(() => {
      //   this.process = false;
      //   callback(true, null);
      // })
      //   .catch(error => {
      //     callback(false, error);
      //     this.process = false;
      //   });
    }
    else {
      callback(false, "No batch");
      this.process = false;
    }
  }

  @action
  async setStudentBatch(item: any, newBatch: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const batchRef = this.ds.batchLevelFireRef();
    const { student, create_by, create_date, program, } = item;
    const studentNewBatchDoc = await this.ds.batchLevelRef().doc(newBatch.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
    const comingStudentDoc = await this.ds.instituteNoScheduleStudentRef(student.key).get().toPromise();
    const testingStudentDoc = await this.ds.scheduleInProgressStudentRef(student.key).get().toPromise();
    const studentData = await this.ds.studentDocument(student.key).get().toPromise();
    const studentDoc = MappingService.pushToObject(studentData);
    const { category } = program;
    const oldBatch = studentDoc[category.key];

    if (oldBatch) {
      const studentOldBatchDoc = await this.ds.batchLevelRef().doc(oldBatch.key)
        .collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
      if (!studentOldBatchDoc.empty) {
        const studentOldBatchData = MappingService.pushToArray(studentOldBatchDoc)[0];
        batch.delete(batchRef.doc(oldBatch.key).collection("students").doc(studentOldBatchData.key));
      }
    }

    if (!studentNewBatchDoc.empty) {
      const studentNewBatchData = MappingService.pushToArray(studentNewBatchDoc)[0];
      batch.delete(batchRef.doc(newBatch.key).collection("students").doc(studentNewBatchData.key))
    }

    const studentRef = this.ds.studentFireS(student.key);
    // const { category } = this.selectedAdmission.program_academic;
    if (!comingStudentDoc.empty) {
      const list = MappingService.pushToArray(comingStudentDoc);
      batch.delete(this.ds.instituteNoScheduleStudentFireRef().doc(list[0].key));
    }
    if (!testingStudentDoc.empty) {
      const testListing = MappingService.pushToArray(testingStudentDoc);
      batch.delete(this.ds.scheduleInProgressFireRef().doc(testListing[0].key))
    }

    batch.update(studentRef, {
      [category.key]: MappingService.batchObj(newBatch),
      update_by: create_by,
      update_date: create_date
    })
    batch.set(batchRef.doc(newBatch.key).collection("students").doc(item.key), item);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }


}
