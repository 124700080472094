import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Payment } from "src/app/stores/payment.store";
import { ConvertService } from "src/app/services/convert.service";

@Component({
  selector: 'app-wing-online-testing',
  templateUrl: './wing-online-testing.component.html',
  styleUrls: ['./wing-online-testing.component.scss']
})
export class WingOnlineTestingComponent implements OnInit {
  @ViewChild("buttonSubmit")
  btnSubmit;
  @ViewChild("username")
  username;
  // @ViewChild("password") password;
  @ViewChild("rest_api_key")
  rest_api_key;
  @ViewChild("return_url")
  return_url;
  @ViewChild("bill_till_number")
  bill_till_number;
  @ViewChild("amount")
  amount;
  @ViewChild("remark")
  remark;
  @ViewChild("transactionId")
  transactionId;

  //Testing Mode
  userName = "online.puc";
  // passWord = "05ba0faf0c8df06f2693efe3d48fdc10";

  //======Testing
  // apiKey = "bdc38d95737599fe50e9cf8b26f66209bc3a390924ecffdbb1c6a313cf54acbf";

  //======Production
  apiKey = "cc7d143d426d6443a248814528716e3fd6dba4a99939db62b6a8b2bb696b562c";

  // returnUrl = "http://localhost:4214/payments/wing/" + '123456' + "/success";

  //======Testing
  // billTillNumber = 2000;

  //======Production
  billTillNumber = 5124;

  payAmount = 0;
  invoiceKey: string;
  studentKey: string;
  items: any;
  process = true;

  constructor(
    public route: ActivatedRoute,
    public afs: AngularFirestore,
    public store: Payment,
  ) { }

  async ngOnInit() {
    this.route.params.forEach(async (params) => {
      this.studentKey = params["id"];
      this.invoiceKey = params["iid"];
      this.store.fetchOnlineTesting(this.studentKey, this.invoiceKey, (grandTotal) => {
        this.payAmount = ConvertService.toNumber(grandTotal);
        this.username.nativeElement.value = this.userName;
        this.rest_api_key.nativeElement.value = this.apiKey;
        this.return_url.nativeElement.value = "https://p.puc.edu.kh/payments/wing-success/" + this.invoiceKey;
        this.bill_till_number.nativeElement.value = this.billTillNumber;
        this.remark.nativeElement.value = this.invoiceKey;
        this.transactionId.nativeElement.value = this.invoiceKey;
        this.amount.nativeElement.value = this.payAmount;
        this.btnSubmit.nativeElement.submit();
        this.process = false;
      });

    });
  }
}