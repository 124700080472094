export const tabs = {

  enrollment: [
    { path: '/enrollment/recommend', label: 'Recommend' },
    { path: '/enrollment/major-schedules', label: 'Major schedules' },
    { path: '/enrollment/all-schedules', label: 'All schedules' },
  ],
  transcript: [
    { path: '/transcript/official', label: 'Official Transcript' },
    { path: '/transcript/unofficial', label: 'Un Official Transcript' },
  ],

}
