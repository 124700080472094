import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PrintService } from 'src/app/services/print.service';
import { Payment } from 'src/app/stores/payment.store';
import { Environment } from 'src/app/stores/environment.store';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public payment: Payment,
    private ps: PrintService,
    public env: Environment
  ) { }

  ngOnInit() {
    this.payment.fetchInvoiceItems(this.data.studentKey, this.data.headerKey, inv => { })
  }

  print() {
    this.ps.print("#printD", "a5l");
  }

}
