import { Cart } from './../../stores/cart.store';
import { Environment } from './../../stores/environment.store';
import { Student } from './../../stores/student.store';
import { Auth } from './../../stores/auth.store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  toggleSidebar;
  constructor(
    public store: Auth,
    public student: Student,
    public env: Environment,
    public cart: Cart,
    private router: Router,
  ) { }

  logOut() {
    this.store.signOut(() => {
      this.router.navigate(['/login']);
    })
  }
  togglesidebar() {
    const body = document.getElementsByClassName('page-wrapper')[0];
    body.classList.toggle('toggled-sidebar');
    this.toggleSidebar = !this.toggleSidebar;
    // this.localStorages
    //   .setItem("toggleSidebar", this.toggleSidebar)
    //   .subscribe(() => { });
  }
  ngOnInit() {
    this.env.fetchConfigToArray(doc => {
      this.store.fetchAccountLink(acc => {
        this.cart.fetchCart(this.env.selectedTerm.key, this.store.student.key);
      })
    })
  }

  _onOpenCart(event) {
    this.cart.fetchCart(this.env.selectedTerm.key, this.store.student.key);
  }

  _removeCart(item) {
    this.cart.removeCart(item, (success, error) => {
    });
  }

}
