import { ConvertService } from './convert.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() { }
  static sumTuitionFees(data: Array<any>) {
    let value = 0;
    const unpaidDocs = data.filter(m => m.scholarshipRef && m.scholarshipRef.key === "unpaid")
    if (unpaidDocs.length > 0) {
      value = unpaidDocs[0].price;
    } else {
      data.forEach(doc => {
        const { scholarship100, loan, scholarship, amount } = doc
        if (scholarship100) {
          value += amount;
        } else {
          value += amount + ConvertService.toNumber(loan) + + ConvertService.toNumber(scholarship)
        }
      })
    }
    return value;
  }

  static batchObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        level: this.toNull(item.level),
        shortName: this.toNull(item.shortName),
      }
    } else return null;
  }
  static groupByOrderDesc(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy], ['desc'])
  }
  static sum(data: Array<any>, field: string) {
    return data.reduce((a, b) => a + Number(b[field]), 0)
  }

  static filter(data: Array<any>, field: string, keyword: any) {
    return _.filter(data, [field, keyword])
  }

  static sumTranscript(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_attempted), 0)
  }

  static sumTotalPoint(data: Array<any>) {
    return data.reduce((acc, cur) => acc + cur.total, 0)
  }

  static sumTotalCredit(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_calculator), 0)
  }

  static sumCreditAttempted(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_attempted), 0)
  }

  static sumCreditCalculator(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_calculator), 0)
  }

  static sumCreditEarned(data: Array<any>) {
    return data.reduce((acc, cur) => acc + cur.credits_earned, 0)
  }

  static pushToArray(data: firestore.QuerySnapshot) {
    if (data.empty) return null;
    return data.docs.map(m => { return { ...m.data() } })
  }
  static pushToObject(data: firestore.DocumentSnapshot) {
    if (!data.exists) return null;
    return data.data();
  }


  static orderBy(data: Array<any>, field: string) {
    return _.orderBy(data, [field])
  }
  static orderByDesc(data: Array<any>, orderBy: string) {
    return _.orderBy(data, [orderBy, 'desc'])
  }
  static orderByASC(data: Array<any>, orderBy: string) {
    return _.orderBy(data, [orderBy, 'asc'])
  }

  static uniqOnTop(data: Array<any>, field: string, filedOrderBy: string) {
    const rows = _.orderBy(data, [filedOrderBy], ['desc'])
    return _.uniqBy(rows, field)
  }

  static groupBy(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy])
  }

  static groupByDesc(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy, 'desc'])
  }

  static toParaKey(name: string) {
    return name.toLowerCase().replace(" ", "_");
  }

  static testingTab(tabs: Array<any>, key: string, iKey: string) {
    const tab = [];
    let i = 0;
    tabs.forEach(m => {
      tab.push({
        path: m.path + "/" + key + "/" + iKey + "/" + m.key,
        label: m.label
      });
      i++;
    });
    return tab;
  }

  static calScholarship(amount, arg: any): any {
    let value = 0;
    let { percentage, cash, loan } = arg;
    const cashAmount = ConvertService.toNumber(cash);
    const percentAmount = ConvertService.toNumber(percentage);
    const loanAmount = ConvertService.toNumber(loan);
    let balanceDue = amount;
    let totalScholarship = 0;
    let totalLoan = 0;
    if (cashAmount !== 0) {
      balanceDue = balanceDue - cashAmount;
      totalScholarship = totalScholarship + cashAmount;
    }
    if (percentAmount !== 0) {
      const totalDisP = balanceDue * percentAmount / 100;
      totalScholarship = totalScholarship + totalDisP;
    }
    if (loanAmount !== 0) {
      const totalDisL = balanceDue * loanAmount / 100;
      totalLoan = totalDisL;
    }
    const totalDis = totalScholarship + totalLoan;
    balanceDue = balanceDue - totalDis;

    value = balanceDue;
    const result = {
      totalLoan: totalLoan,
      totalScholarship: totalScholarship,
      balanceDue: value,
      deductAmount: totalDis
    }
    return result;
  }

  public static toNull(value) {
    if (value === "" || value === undefined || value === null) {
      return null;
    }
    return value;
  }

  static studentObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        id: this.toNull(item.StudentId),
        puc_id: this.toNull(item.puc_id),
        serial_id: this.toNull(item.serial_id),
        PUCCODE: this.toNull(item.PUCCODE),
        first_name: this.toNull(item.first_name),
        last_name: this.toNull(item.last_name),
        full_name: this.toNull(item.full_name),
        mobile_phone: this.toNull(item.mobile_phone),
        email_address: this.toNull(item.email_address),
        email: this.toNull(item.email),
        gender: this.toNull(item.gender),
        program_academic: this.toNull(item.program_academic),
      };
    } else return null;
  }

  static admissionObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        studentId: this.toNull(item.studentId),
        PUCCODE: this.toNull(item.PUCCODE),
        PUCID: this.toNull(item.PUCID),
        faculty: this.toNull(item.faculty),
        major: this.majorObj(item.major),
        curriculum: this.toNull(item.curriculum),
        puc_generation: this.toNull(item.puc_generation),
        term: this.toNull(item.term),
        shift: this.toNull(item.shift),
        program: this.toNull(item.program),
        diplomaType: this.toNull(item.diplomaType),
        admission_date: this.toNull(item.admission_date),
        admission_date_key: this.toNull(item.admission_date_key),
        FacultyName: this.toNull(item.FacultyName),
        Major: this.majorObj(item.Major),
        Program: this.toNull(item.Program),
        ProgramLevel: this.toNull(item.ProgramLevel),
        SchoolTime: this.toNull(item.SchoolTime),
        TermCode: this.toNull(item.TermCode),
        Generation: this.toNull(item.Generation),
        DegreeType: this.toNull(item.DegreeType),
      };
    } else return null;
  }

  static majorObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        faculty: this.toNull(item.faculty),
        name: this.toNull(item.name),
        shortName: this.toNull(item.shortName),
        khmerName: this.toNull(item.khmerName),
        birthDay: this.toNull(item.birthDay),
        program: this.toNull(item.program),
      };
    }
    else return null;
  }

  static curriculumObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        create_date: this.toNull(item.create_date),
        create_by: this.toNull(item.create_by),
        code: this.toNull(item.code),
        major: this.majorObj(item.major),
      }
    } else return null;
  }

  static subjectObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        create_date: this.toNull(item.create_date),
        create_by: this.toNull(item.create_by),
        code: this.toNull(item.code),
        program: this.toNull(item.program),
        category: this.toNull(item.category),
        khmer_name: this.toNull(item.khmer_name),
        labHours: this.toNull(item.labHours),
        lecHours: this.toNull(item.lecHours),
        credit: this.toNull(item.credit),
      };
    } else return null;
  }

  static facultyObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        khmerName: this.toNull(item.khmerName),
        shortName: this.toNull(item.shortName),
      };
    } else return null;
  }

  static userObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        displayName: this.toNull(item.displayName),
        email: this.toNull(item.email),
        role: this.toNull(item.role),
        campus: this.toNull(item.campus),
        uid: this.toNull(item.uid),
      };
    } else return null;
  }

  static campusObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        shortName: this.toNull(item.shortName),
      };
    } else return null;
  }

  static termObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        code: item.code,
        name: item.name,
        page_key: item.page_key,
        startterm: this.toNull(item.startterm),
        startDateKey: this.toNull(item.startDateKey),
        endterm: this.toNull(item.endterm),
        endTermKey: this.toNull(item.endTermKey),
        termstatus: this.toNull(item.termstatus),
        note: this.toNull(item.note),
        memo: this.toNull(item.memo),
        maxEnroll: this.toNull(item.maxEnroll),
        status: this.toNull(item.status),
        startEnroll: this.toNull(item.startEnroll),
        startEnrollKey: this.toNull(item.startEnrollKey),
        endEnroll: this.toNull(item.endEnroll),
        endEnrollKey: this.toNull(item.endEnrollKey),
        endPayment: this.toNull(item.endPayment),
        endPaymentKey: this.toNull(item.endPaymentKey),
        dropClass: this.toNull(item.dropClass),
        dropClassKey: this.toNull(item.dropClassKey),
        term_type: this.toNull(item.term_type),
        termType: this.toNull(item.termType),
      };
    } else return null;
  }

  static generationObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        campus: (item.campus),
        generation: this.toNull(item.generation),
      };
    } else return null;
  }

  static abcCourseObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        campus: this.toNull(item.campus),
        code: this.toNull(item.code),
        endTermKey: this.toNull(item.endTermKey),
        endcourse: this.toNull(item.endcourse),
        generation: {
          key: item.generation.key,
          generation: item.generation.generation,
        },
        name: this.toNull(item.name),
        page_key: this.toNull(item.page_key),
        startDateKey: this.toNull(item.startDateKey),
        startcourse: this.toNull(item.startcourse),
        term: {
          key: item.term.key,
          name: item.term.name,
        },
      };
    } else return null;
  }

  static feeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        code: this.toNull(item.code),
        is_academic: this.toNull(item.is_academic),
        name: this.toNull(item.name),
        page_key: this.toNull(item.page_key),
        price: this.toNull(item.price),
        program: this.toNull(item.program),
        program_type: this.toNull(item.program_type),
        priority: this.toNull(item.priority),
      }
    } else return null;
  }

  static roomObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        khmer_name: this.toNull(item.khmer_name),
        building: this.toNull(item.building),
        campus: this.campusObj(item.campus),
        floor: this.toNull(item.floor),
        roomType: this.toNull(item.roomType),
        max_seats: this.toNull(item.max_seats),
      }
    } else return null;
  }

  static positionObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        shortcut: this.toNull(item.shortcut),
      }
    } else return null;
  }

  static instructorObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        account_number: this.toNull(item.account_number),
        code: this.toNull(item.code),
        display_name: this.toNull(item.display_name),
        dob: this.toNull(item.dob),
        email: this.toNull(item.email),
        first_name: this.toNull(item.first_name),
        last_name: this.toNull(item.last_name),
        full_name: this.toNull(item.full_name),
        gender: this.toNull(item.gender),
        hire_date: this.toNull(item.hire_date),
        phone: this.toNull(item.phone),
        position: this.positionObj(item.position),
        title: this.toNull(item.title),
      }
    } else return null;
  }

  static scheduleSubjectObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        credit: this.toNull(item.credit),
        labHours: this.toNull(item.labHours),
        lecHours: this.toNull(item.lecHours),
        courseCode: this.toNull(item.courseCode),
        courseName: this.toNull(item.courseName),
        credits_attempted: this.toNull(item.credits_attempted),
        credits_calculator: this.toNull(item.credits_calculator),
        credits_earned: this.toNull(item.credits_earned),
        gradePoint: this.toNull(item.gradePoint),
        gradeRef: this.toNull(item.gradeRef),
        isCompleted: this.toNull(item.isCompleted),
        overall_grade: this.toNull(item.overall_grade),
        subject: this.subjectObj(item.subject),
        curriculum: this.curriculumObj(item.curriculum),
        subject_type: this.toNull(item.subject_type),
      }
    } else return null;
  }

  static shiftObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        id: this.toNull(item.id),
        duration: this.toNull(item.duration),
        paymentRate: this.toNull(item.paymentRate),
        name: this.toNull(item.name),
        order: this.toNull(item.order),
      }
    } else return null;
  }

  static sessionObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        days: this.toNull(item.days),
        fromHours: this.toNull(item.fromHours),
        fromHoursNumber: this.toNull(item.fromHoursNumber),
        name: this.toNull(item.name),
        toHours: this.toNull(item.toHours),
        toHoursNumber: this.toNull(item.toHoursNumber),
        shift: this.shiftObj(item.shift)
      }
    } else return null;
  }

  static courseObj(item: any) {
    return {
      key: item.key,
      batchKey: item.batchKey,
      campus: this.campusObj(item.campus),
      code: this.toNull(item.code),
      curriculum: this.toNull(item.curriculum),
      fee: this.feeObj(item.fee),
      generation: this.generationObj(item.generation),
      instructor: this.toNull(item.instructor),
      isHeader: this.toNull(item.isHeader),
      major: this.majorObj(item.major),
      note: this.toNull(item.note),
      memo: this.toNull(item.memo),
      payment_type: this.toNull(item.payment_type),
      program_type: this.toNull(item.program_type),
      room: this.roomObj(item.room),
      scheduleType: this.toNull(item.scheduleType),
      schedule_subject: this.scheduleSubjectObj(item.schedule_subject),
      session: this.sessionObj(item.session),
      submit_grade: this.toNull(item.submit_grade),
      term: this.termObj(item.term),
      secondarySession: this.sessionObj(item.secondarySession),
      secondaryCampus: this.campusObj(item.secondaryCampus),
      secondaryRoom: this.roomObj(item.secondaryRoom),
    };
  }

  static instituteObj(item: any) {
    return {
      key: item.key,
      code: item.code,
      name: item.name,
      instituteType: item.instituteType,
      shortName: item.shortName,
    };
  }

  static transcriptObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        batchKey: this.toNull(item.batchKey),
        key: this.toNull(item.key),
        submit_grade: this.toNull(item.submit_grade),
        code: this.toNull(item.code),
      }
    } else return null;
  }

  static getForeignerFee(student, setting, fee) {
    const { nationality } = student;
    const { foreigner_student } = setting;
    if (!nationality) return ConvertService.toNumber(fee.price);
    if (nationality.key === foreigner_student.key) return ConvertService.toNumber(fee.foreigner)
    return ConvertService.toNumber(fee.price)
  }

  static getFeeRef(settings, courseCode, currentFee, paymentType, scheduleType) {
    const { academic_fee, uat_fee, academic_weekend_fee, uep_fee } = settings;
    if (scheduleType.key === academicProgramObj.bachelor.key || scheduleType.key === academicProgramObj.associate.key) {
      let feeBa = paymentType.key === 1 ? academic_fee : academic_weekend_fee;
      const validUat = uatCourses.filter(m => m.trim().toLowerCase() === courseCode.trim().toLowerCase());
      if (validUat.length > 0) {
        feeBa = uat_fee
      }

      const validUEP = uepCourses.filter(m => m.trim().toLowerCase() === courseCode.trim().toLowerCase()).length > 0;
      if (validUEP) {
        feeBa = uep_fee
      }
      return feeBa;
    }
    return currentFee;
  }
}

export const academicProgram = [
  { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
]

export const academicProgramObj = {
  associate: { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  bachelor: { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  pre_master: { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  master: { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  postgraduate: { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
}

export function pushToArray(data: firestore.QuerySnapshot) {
  if (data.empty) return [];
  return data.docs.map(m => { return { ...m.data() } })
}

export function pushToObject(data: firestore.DocumentSnapshot) {
  if (!data.exists) return null;
  return data.data();
}

const uatCourses = [
  "PE 100",
  "PE 101",
  "PE 102",
  "AESP 101",
  "AESP 103",
  "AESP 102",
  "AESP 104",
  "AESP 105",
  "AESP 106"
]

const uepCourses = [
  "UEP LEVEL A",
  "UEP LEVEL B",
  "UEP LEVEL C",
]