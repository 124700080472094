import { AuthService } from './../auth/auth.service';
import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { pushToArray, pushToObject } from '../services/mapping.service';
import { Router } from '@angular/router';
import { Pages } from '../dummy/pages';

@Injectable()
export class Auth {
  @observable public user = null;
  @observable public accountLink = null;
  @observable public student = null;
  @observable public process = false;
  @observable public loading = false;

  constructor(private ds: DataService,
    private router: Router,
    public auth: AuthService,
  ) {
    this.auth.authRef().user.subscribe(doc => {
      if (doc) {
        this.fetchUser();
      }
    })
  }

  @action
  fetchUser() {
    const u = this.auth.getUser();
    this.user = u;
  }

  @action
  fetchAccountLink(callback) {
    const { uid } = this.auth.getUser();
    this.process = true;
    if(uid){
      this.ds.studentAccountRef(uid).valueChanges().subscribe(docs => {
        this.accountLink = docs.length > 0 ? docs[0] : null;
        this.process = false;
        if (this.accountLink)
          this.student = this.accountLink.student;
        callback(this.accountLink)
      })
    }else callback('null');
  }


  @action
  async fetchStudentByAccount(account: any) {
    this.loading = true;
    const { uid } = account;
    const docs = await this.ds.studentAccountRef(uid).get().toPromise();
    const students = pushToArray(docs);
    if (!docs.empty) {
      this.accountLink = students[0];
      const { student } = this.accountLink;
      const studentData = await this.ds.studentDocument(student.key).get().toPromise();
      this.student = pushToObject(studentData);
      this.loading = false;
      return this.student;
    }
    this.loading = false;
    this.router.navigate([Pages.notFoundRoute]);
    return null;
  }



  @action
  canActive(callback) {
    this.auth.isLoggedIn().then(doc => {
      callback(doc);
    });
  }

  @action
  signIn(email, password, callback) {
    this.process = true;
    const pucMail = email + "@puc.edu.kh";
    this.auth.emailSignIn(pucMail, password).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      callback(false, error);
    });
  }

  @action
  signOut(callback) {
    this.process = true;
    this.auth.signOut().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }
}
