import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-msc-card',
  templateUrl: './msc-card.component.html',
  styleUrls: ['./msc-card.component.scss']
})
export class MscCardComponent implements OnInit {
  @Output() onPress = new EventEmitter();
  @Input() data: any;
  @Input() process: boolean;
  @Input() term: any;
  @Input() cart: Array<any>;
  @Input() canEnroll: boolean;

  css:string;
  enroll: boolean = false;
  constructor(
  ) { }

  ngOnInit() {
    this.css=null;
    if(!this.data) return;
    const closeEnroll = ConvertService.toDateKey(this.term.endEnroll.toDate());
    const { limit, enrollmentStudents, overLimit } = this.data;
    if (!overLimit && enrollmentStudents) {
      if (limit === enrollmentStudents.length) {
        this.canEnroll = false;
      }
    }
    const list = this.cart.filter(m => m.course && m.course.key === this.data.key);
    if (list.length > 0){
      this.canEnroll = false;
      this.css="done"
    }
    if (this.canEnroll) {
      if (closeEnroll < ConvertService.toDateKey(new Date())) {
        this.canEnroll = false;
      }
    }
  }

}
