import { Environment } from './../../stores/environment.store';
import { Component, OnInit,Inject,PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-app-outlet',
  templateUrl: './app-outlet.component.html',
  styleUrls: ['./app-outlet.component.scss']
})
export class AppOutletComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  ) { }


  ngOnInit() {
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

}
