import { AppOutletComponent } from './shared/app-outlet/app-outlet.component';
import { EnrollmentBatchComponent } from './pages/enrollment/enrollment-batch/enrollment-batch.component';
import { APP_SERVICES } from "./services/app.services";
import { DataService } from "./services/data.service";
import { AuthService } from "./auth/auth.service";
import { AuthLayoutComponent } from "./shared/auth-layout/auth-layout.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./pages/home/home.component";
import { AppLayoutComponent } from "./shared/app-layout/app-layout.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { CampusListComponent } from "./components/campus-list/campus-list.component";
import { ListingComponent } from "./pages/listing/listing.component";
import { TaskBarComponent } from "./components/task-bar/task-bar.component";
import { MaterialModule } from "./modules/material/material.module";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { SigninComponent } from "./pages/signin/signin.component";
import { LoginShellComponent } from "./components/login-shell/login-shell.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MobxAngularModule } from "mobx-angular";
import { APP_STORES } from "./stores/app.store";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { GradeComponent } from "./pages/grade/grade.component";
import { PrintService } from "./services/print.service";
import { NguCarouselModule } from "@ngu/carousel";
import { PucButtonComponent } from "./components/puc-button/puc-button.component";
import { OnlyNumberDirective } from "./directives/only-number.directive";
import { PucLoaderComponent } from "./components/puc-loader/puc-loader.component";
import { DateMediumPipe } from "./pipes/date-medium.pipe";
import { FilterTermPipe } from "./pipes/filter-term.pipe";
import { MscCardComponent } from "./components/msc-card/msc-card.component";
import { EnrollmentComponent } from "./pages/enrollment/enrollment.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { ScheduleComponent } from "./pages/schedule/schedule.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { ReportHistorComponent } from "./pages/report-histor/report-histor.component";
import { HelpComponent } from "./pages/help/help.component";
import { EmptyComponent } from "./components/empty/empty.component";
import { DaysSchedulePipe } from "./pipes/days-schedule.pipe";
import { ConfirmSuccessComponent } from "./components/confirm-success/confirm-success.component";
import { ConfirmEnrollmentComponent } from "./components/confirm-enrollment/confirm-enrollment.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ValidSchedulePipe } from "./pipes/valid-schedule.pipe";
import { EnrollmentRouteComponent } from "./pages/enrollment/enrollment-route/enrollment-route.component";
import { EnrollmentCartComponent } from "./pages/enrollment/enrollment-cart/enrollment-cart.component";
import { RequestSuccessComponent } from "./pages/enrollment/request-success/request-success.component";
import { WingPaywayComponent } from "./pages/payments/wing-payway/wing-payway.component";
import { WingCallbackComponent } from "./pages/payments/wing-callback/wing-callback.component";
import { ConfirmRequestEnrollmentComponent } from "./components/confirm-request-enrollment/confirm-request-enrollment.component";
import { WingSuccessComponent } from "./pages/payments/wing-success/wing-success.component";
import { DeviceDetectorModule } from "ngx-device-detector";
import { StudentSchedulePipe } from "./pipes/student-schedule.pipe";
import { MatScheduleComponent } from "./components/mat-schedule/mat-schedule.component";
import { UnderConstructionComponent } from "./components/under-construction/under-construction.component";
import { CreditEarnedPipe } from "./pipes/credit-earned.pipe";
import { HolidayComponent } from "./pages/holiday/holiday.component";
import { HolidayByYearPipe } from "./pipes/holiday-by-year.pipe";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClassSchedulesComponent } from './pages/enrollment/class-schedules/class-schedules.component';
import { GenerationScheduleComponent } from "./pages/enrollment/generation-schedule/generation-schedule.component";
import { DialogScheduleComponent } from './pages/enrollment/dialog-schedule/dialog-schedule.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmDeleteComponent } from "./components/confirm-delete/confirm-delete.component";
import { ScanQrCodeComponent } from "./components/scan-qrcode/scan-qrcode.component";
import { QRCodeModule } from 'angularx-qrcode';
import { PolicyComponent } from "./components/policy/policy.component";
import { StudentScheduleComponent } from "./components/student-schedule/student-schedule.component";
import { InvoiceDialogComponent } from "./components/invoice-dialog/invoice-dialog.component";
import { InvoicePaymentComponent } from "./components/invoice-payment/invoice-payment.component";
import { EngScheduleComponent } from './pages/enrollment/eng-schedule/eng-schedule.component';
import { StudentEnrollmentCartComponent } from './components/student-enrollment-cart/student-enrollment-cart.component';
import { PaginationLoaderComponent } from './components/pagination-loader/pagination-loader.component';
import { MatPaymentComponent } from './components/mat-payment/mat-payment.component';
import { UnOfficialTranscriptComponent } from './pages/un-official-transcript/un-official-transcript.component';
import { PaymentOutletComponent } from './pages/payments/payment-outlet/payment-outlet.component';
import { WingOnlineTestingComponent } from './pages/payments/wing-online-testing/wing-online-testing.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto"
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    CampusListComponent,
    ListingComponent,
    TaskBarComponent,
    AuthLayoutComponent,
    SigninComponent,
    LoginShellComponent,
    SidebarComponent,
    GradeComponent,
    PucButtonComponent,
    OnlyNumberDirective,
    PucLoaderComponent,
    DateMediumPipe,
    FilterTermPipe,
    MscCardComponent,
    EnrollmentComponent,
    PaymentsComponent,
    ScheduleComponent,
    ProfileComponent,
    SettingsComponent,
    ReportHistorComponent,
    HelpComponent,
    EmptyComponent,
    DaysSchedulePipe,
    ConfirmSuccessComponent,
    ConfirmEnrollmentComponent,
    SpinnerComponent,
    ValidSchedulePipe,
    EnrollmentRouteComponent,
    EnrollmentCartComponent,
    RequestSuccessComponent,
    WingPaywayComponent,
    WingCallbackComponent,
    ConfirmRequestEnrollmentComponent,
    WingSuccessComponent,
    StudentSchedulePipe,
    MatScheduleComponent,
    UnderConstructionComponent,
    CreditEarnedPipe,
    HolidayComponent,
    HolidayByYearPipe,
    DateFormatPipe,
    ClassSchedulesComponent,
    GenerationScheduleComponent,
    DialogScheduleComponent,
    ConfirmDeleteComponent,
    ScanQrCodeComponent,
    PolicyComponent,
    StudentScheduleComponent,
    InvoiceDialogComponent,
    InvoicePaymentComponent,
    EnrollmentBatchComponent,
    EngScheduleComponent,
    AppOutletComponent,
    StudentEnrollmentCartComponent,
    PaginationLoaderComponent,
    MatPaymentComponent,
    UnOfficialTranscriptComponent,
    PaymentOutletComponent,
    WingOnlineTestingComponent,
  ],
  entryComponents: [
    ConfirmSuccessComponent,
    ConfirmEnrollmentComponent,
    ConfirmRequestEnrollmentComponent,
    DialogScheduleComponent,
    ConfirmDeleteComponent,
    InvoiceDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MobxAngularModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    QRCodeModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase, ""),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NguCarouselModule,
    SwiperModule,
    InfiniteScrollModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthService,
    DataService,
    PrintService,
    APP_STORES,
    APP_SERVICES,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
