import { IPreEnrollment } from 'src/app/interfaces/enrollment';
import { MappingService } from './../../../services/mapping.service';
import { ConfirmEnrollmentComponent } from 'src/app/components/confirm-enrollment/confirm-enrollment.component';
import { ConvertService } from 'src/app/services/convert.service';
import { ActivatedRoute } from '@angular/router';
import { Auth } from 'src/app/stores/auth.store';
import { Environment } from './../../../stores/environment.store';
import { Cart } from 'src/app/stores/cart.store';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { preEnrollStatus } from 'src/app/dummy/status';
import { Pages } from 'src/app/dummy/pages';

@Component({
  selector: 'app-enrollment-batch',
  templateUrl: './enrollment-batch.component.html',
  styleUrls: ['./enrollment-batch.component.scss']
})

export class EnrollmentBatchComponent implements OnInit {
  loading = true;
  selectedAdmission: any;
  batch: any;
  schedules: Array<any> = [];
  termKey: string;
  shiftKey: string;
  majorKey: string;

  type: string;
  constructor(
    public env: Environment,
    public store: Student,
    public cart: Cart,
    private snackBar: MatSnackBar,
    public router: Router,
    private ds: DataService,
    public auth: Auth,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const account = await this.env.fetchAccount();
    const student = await this.auth.fetchStudentByAccount(account);
    this.route.params.forEach(async param => {
      this.schedules = []
      const { id, termKey, majorKey, shiftKey } = param;
      this.termKey = termKey;
      this.majorKey = ConvertService.toNull(majorKey);
      this.shiftKey = shiftKey;
      this.type = id;
      this.env.tabs = [
        { path: '/enrollment/recommend/' + termKey + '/' + shiftKey, label: 'Recommend' },
        { path: '/enrollment/major-schedules/' + termKey + '/' + shiftKey, label: 'Major schedules' },
        { path: '/enrollment/adding-class/' + termKey + '/' + shiftKey, label: 'Adding class' },
        { path: '/enrollment/all-schedules/' + termKey + '/' + shiftKey, label: 'All schedules' },
        // { path: '/enrollment/curriculum/' + termKey + '/' + shiftKey, label: 'Curriculum' },
      ]
      this.loading = true;
      this.env.fetchSelectedTerm(ConvertService.toNull(termKey), ConvertService.toNull(id), shiftKey)
      if (student) {
        await this.env.fetchConfiguration(shiftKey);
        const selectedAdmission = await this.store.fetchStudentAdmission(student.key);
        if (selectedAdmission) {
          this.selectedAdmission = this.store.selectedAdmission;
          this.store.fetchStudentSchedule(termKey, student.key, shiftKey)
          this.cart.fetchCartToArray(termKey, student.key, async cart => {
            const { major, puc_generation } = selectedAdmission;
            if (id === 'recommend') {
              this.batch = await this.store.fetchStudentBatch(major.key, puc_generation.key, shiftKey)
              if (this.batch) {
                this.schedules = await this.store.fetchBatchSchedules(termKey, this.batch.key, shiftKey);
                this.loading = false;
              }
              else {
                this.schedules = []
                this.loading = false;
              }
            }
            else if (id === `major-schedules`) {
              this.schedules = await this.store.fetchMajorSchedules(termKey, major.key, shiftKey);
              this.loading = false;
            }
            else if (id === `adding-class`) {
              this.schedules = await this.store.fetchBatchSchedules(termKey, Pages.addingClassKey, shiftKey);
              this.loading = false;
            }
            else if (id === `all-schedules`) {
              const key = ConvertService.toNull(majorKey);
              const rows = await this.store.fetchAllSchedules(termKey, key, shiftKey);
              this.schedules = this.schedules.concat(rows);
              this.loading = false;
            }
            else {
              this.schedules = []
              this.loading = false;
            }
          });
        }
      }
      else {
        this.loading = false;
      }
    })

  }


  _confirmEnroll(data) {
    let dialogRef = this.dialog.open(ConfirmEnrollmentComponent, {
      data: data,
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        const { major, major_generation } = this.store.selectedAdmission;
        const item: IPreEnrollment = {
          key: this.ds.createId(),
          course: MappingService.courseObj(data),
          create_date: new Date(),
          create_date_key: ConvertService.dateKey(),
          status: preEnrollStatus.pending,
          isCompleted: false,
          isRequest: false,
          isPaid: false,
          note: null,
          major: MappingService.majorObj(major),
          page_key: ConvertService.pageKey(),
          generation: ConvertService.toNull(major_generation),
          student: MappingService.studentObj(this.auth.student),
          term: MappingService.termObj(this.env.selectedTerm)
        }
        this.cart.addToCart(item, (success, error) => {
          if (success) {
          } else {
            this.snackBar.open(error, "Error");
          }
        })
      }
    });
  }

  async _onScroll() {
    if (this.type !== "all-schedules") return;
    const rows = await this.store.onScrollSchedule(this.termKey, this.majorKey, this.shiftKey);
    if (rows && rows.length > 0)
      this.schedules = this.schedules.concat(rows);
  }

}
