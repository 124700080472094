import { Auth } from 'src/app/stores/auth.store';
import { Component, OnInit } from "@angular/core";
import { Student } from "src/app/stores/student.store";
import { Router, ActivatedRoute } from "@angular/router";
import { Cart } from "src/app/stores/cart.store";
import { Environment } from "src/app/stores/environment.store";
import { Pages } from 'src/app/dummy/pages';

@Component({
  selector: "app-generation-schedule",
  templateUrl: "./generation-schedule.component.html",
  styleUrls: ["./generation-schedule.component.scss"]
})
export class GenerationScheduleComponent implements OnInit {
  loading = true;
  constructor(
    public env: Environment,
    public store: Student,
    public cart: Cart,
    private router: Router,
    private auth: Auth,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const account = await this.env.fetchAccount();
    const student = await this.auth.fetchStudentByAccount(account);
    const shifts=await this.store.fetchShift();
    if (student) {
      await this.store.fetchStudentAdmission(student.key);
      this.loading = false;
      const { selectedTerm } = this.env;
      const { selectedAdmission } = this.store;
      if (selectedAdmission) {
        const shift = selectedAdmission.shift?selectedAdmission.shift:shifts[0];
        this.router.navigate([`/enrollment/recommend/${selectedTerm.key}/${shift.key}`])
      }
      else {
        this.router.navigate([Pages.notFoundRoute])
      }
    }
    else {
      this.router.navigate([Pages.notFoundRoute])
    }
  }


}
