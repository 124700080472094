import { Cart } from './../../stores/cart.store';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Auth } from './../../stores/auth.store';
import { Environment } from './../../stores/environment.store';
import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Router } from '@angular/router';
import { IPreEnrollment } from 'src/app/interfaces/enrollment';
import { DataService } from 'src/app/services/data.service';
import { ConvertService } from 'src/app/services/convert.service';
import { MappingService } from 'src/app/services/mapping.service';
import { tabs } from 'src/app/dummy/tabs';
import { preEnrollStatus } from 'src/app/dummy/status';
import { ConfirmSuccessComponent } from 'src/app/components/confirm-success/confirm-success.component';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { searchFilterBy } from 'src/app/dummy/search';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements OnInit {
  process = false;
  
  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = searchFilterBy;
  loading = false;

  constructor(
    public env: Environment,
    public store: Student,
    public cart: Cart,
    private snackBar: MatSnackBar,
    public router: Router,
    private ds: DataService,
    public auth: Auth,
    public dialog: MatDialog,
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {
    this.process = true;
    this.auth.fetchUser();
    this.form = this.fb.group({
      searchType: [this.filterBy[0], [Validators.required]],
      search: [null]
    });
    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];
    this.searchType.disable();

    this.env.fetchConfigToArray(config => {
      this.auth.fetchAccountLink(account => {
        if (account) {
          this.store.fetchStudent(account.studentKey);
          this.store.fetchAdmission(account.studentKey,async (doc) => {
            //student?.selectedAdmission?.admission_type?.key!==2
            
            const { term } = config;
            const { major } = doc;
            this.cart.fetchCartToArray(term.key, account.studentKey, (carts) => {
              this.store.fetchEnrollment(term.key, account.studentKey, (enrolls) => {
                this.store.fetchSchedules(term.key, major.key, carts, enrolls);
              })
            });
            this.process = false;
          })
        }
        else {
          this.router.navigate(['/not-found-account']);
        }
      })
    })
  }


  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _selectionChange(event) {
    const { value } = event;
    this.store.filterType = value;
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }
  _onSearch(f:any){
    const {search}=f;
    if(search){

    }
  }


  _onAddCourse(course) {
    if (this.cart.data.length === 10) {
      this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Max enroll 10 subjects", subTitle: "Pre enrollment allow only 10 subjects." },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      return;
    };
    if (!this.store.student) return;
    const { subject } = course.schedule_subject;
    const { enrollment, cart } = this.store;
    const { code } = subject;
    const hasInCart = cart.filter(m => m.course && m.course.schedule_subject.subject.code === code).length > 0;
    if (hasInCart) {
      this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Pre-enrollment", subTitle: "Course already exist in card" },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      return;
    };
    const hasInEnrollment = enrollment.filter(m => m.courseCode === code).length > 0;
    if (hasInEnrollment) {
      this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Pre-enrollment", subTitle: "Course already exist in card" },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      return;
    };

    let { credit } = this.store.student;
    if (credit || credit > 0) {
      let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Block Enrollment", subTitle: "Your account cannot enroll, you must clear unpaid or penalty payment." },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    } else {
      const { major, major_generation } = this.store.selectedAdmission;
      const item: IPreEnrollment = {
        key: this.ds.createId(),
        course: MappingService.courseObj(course),
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        status: preEnrollStatus.pending,
        isCompleted: false,
        isRequest: false,
        isPaid: false,
        note: null,
        major: MappingService.majorObj(major),
        page_key: ConvertService.pageKey(),
        generation: ConvertService.toNull(major_generation),
        student: MappingService.studentObj(this.auth.student),
        term: MappingService.termObj(this.env.selectedTerm)
      }
      this.cart.addToCart(item, (success, error) => {
        if (success) {
        } else {
          this.snackBar.open(error, "Error");
        }
      })
    }
  }

}
