import { Schedule } from './schedule.store';
import { Cart } from './cart.store';
import { Environment } from './environment.store';
import { Transcript } from './transcript.store';
import { Student } from './student.store';
import { Auth } from './auth.store';
import { Campus } from './campus.store';
import { Terms } from './term.store';
import { Cashier } from './cashier.store';
import {Payment} from './payment.store'
import { Holiday } from './holiday.store';
import { InstituteEnrollment } from './institute-enrollment.store';
import { Scholarship } from './scholarship.store';
export const APP_STORES = [
  Terms,
  Campus,
  Cashier,
  Auth,
  Student,
  Transcript,
  Environment,
  Cart,
  Payment,
  Schedule,
  Holiday,
  InstituteEnrollment,
  Scholarship
];
