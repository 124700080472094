import { GenerationScheduleComponent } from './pages/enrollment/generation-schedule/generation-schedule.component';
import { AppOutletComponent } from './shared/app-outlet/app-outlet.component';
import { EnrollmentBatchComponent } from './pages/enrollment/enrollment-batch/enrollment-batch.component';
import { HelpComponent } from './pages/help/help.component';
import { ListingComponent } from './pages/listing/listing.component';
import { HomeComponent } from './pages/home/home.component';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { SigninComponent } from './pages/signin/signin.component';
import { AuthGuard } from './auth/auth.guard';
import { GradeComponent } from './pages/grade/grade.component';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ReportHistorComponent } from './pages/report-histor/report-histor.component';
import { EnrollmentRouteComponent } from './pages/enrollment/enrollment-route/enrollment-route.component';
import { EnrollmentCartComponent } from './pages/enrollment/enrollment-cart/enrollment-cart.component';
import { RequestSuccessComponent } from './pages/enrollment/request-success/request-success.component';
import { WingPaywayComponent } from './pages/payments/wing-payway/wing-payway.component';
import { WingCallbackComponent } from './pages/payments/wing-callback/wing-callback.component';
import { WingSuccessComponent } from './pages/payments/wing-success/wing-success.component';
import { HolidayComponent } from './pages/holiday/holiday.component';
import { ClassSchedulesComponent } from './pages/enrollment/class-schedules/class-schedules.component';
import { ScanQrCodeComponent } from './components/scan-qrcode/scan-qrcode.component';
import { PolicyComponent } from './components/policy/policy.component';
import { UnOfficialTranscriptComponent } from './pages/un-official-transcript/un-official-transcript.component';
import { PaymentOutletComponent } from './pages/payments/payment-outlet/payment-outlet.component';
import { WingOnlineTestingComponent } from './pages/payments/wing-online-testing/wing-online-testing.component';

const routes: Routes = [
  {
    path: "policy", component: PolicyComponent,
  },
  {
    path: "payments",
    component: PaymentOutletComponent,
    children: [
      { path: "wing-online-testing/:id/:iid", component: WingOnlineTestingComponent },
      { path: "wing/:id/:iid", component: WingPaywayComponent },
      { path: "wing-success/:id", component: WingSuccessComponent },
    ]
  },

  {
    path: "scan-qrcode", component: ScanQrCodeComponent,
  },
  // {
  //   path: "payments/wing/:id",component: WingPaywayComponent,
  // },
  // {
  //   path: "wing-payment-success/:id",component: WingSuccessComponent,
  // },
  {
    path: 'login',
    component: AuthLayoutComponent,
    children: [{ path: '', component: SigninComponent, pathMatch: 'full' }]
  },
  {
    path: '', component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: 'listing', component: ListingComponent },
      { path: 'holiday', component: HolidayComponent },

      { path: 'schedules', component: ScheduleComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'report-history', component: ReportHistorComponent },
      { path: 'help', component: HelpComponent },
      { path: 'request-enroll', component: RequestSuccessComponent },

      {
        path: "transcript",
        component: AppOutletComponent,
        children: [
          { path: "", redirectTo: "official", pathMatch: "full" },
          { path: 'official', component: GradeComponent },
          { path: 'official/:id', component: GradeComponent },
          { path: 'unofficial', component: UnOfficialTranscriptComponent },
          { path: 'unofficial/:id', component: UnOfficialTranscriptComponent },
        ]
      },

      {
        path: "payment",
        component: AppOutletComponent,
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: "list", component: PaymentsComponent },
          // { path: "wing/:id", component: WingPaywayComponent },
          { path: "callback/:id", component: WingCallbackComponent },
        ]
      },
      {
        path: "enrollment",
        component: EnrollmentRouteComponent,
        children: [
          { path: "", redirectTo: "recommend", pathMatch: "full" },
          { path: "recommend", component: GenerationScheduleComponent },
          { path: ":id/:termKey/:shiftKey", component: EnrollmentBatchComponent },
          { path: "request-cart", component: EnrollmentCartComponent },
          { path: "request-success", component: RequestSuccessComponent },
          { path: "class-schedules", component: ClassSchedulesComponent },
          { path: "list", component: EnrollmentComponent },
        ]
      },
      {
        path:"not-found-account",
        component:PolicyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
