import { MappingService, pushToObject, pushToArray } from './../services/mapping.service';
import { AuthService } from "./../auth/auth.service";
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { Pages } from '../dummy/pages';

@Injectable()
export class Student {
  @observable public student = null;
  @observable public admissions = null;
  @observable public selectedAdmission = null;
  @observable public schedules = [];
  @observable public allSchedules = [];
  @observable public emptyEnroll = false;

  @observable public process = false;
  @observable public loading = true;
  @observable public cart = [];
  @observable public enrollment = [];
  @observable public emptySchedules = false;
  @observable public batchSchedules = [];
  @observable public emptyBatchSchedules = false;

  @observable public course = [];
  @observable public emptyCourse = false;
  @observable public scholarship = null;
  @observable public prepaid = null;
  @observable public groups = null;
  @observable public filterType = null;
  @observable public dataSources = [];
  @observable public shift = [];
  @observable public selectedShift = null;
  @observable
  public fetching = false;
  @observable
  public done = false;

  private lastVisible: any = null;
  constructor(
    private ds: DataService,
    public auth: AuthService,
  ) { }

  @action
  async fetchAllSchedules(termKey, majorKey, shiftKey) {
    this.loading = true;
    const docs = await this.ds.studentAllScheduleRef(termKey, majorKey, shiftKey, this.lastVisible).get().toPromise();
    if (docs.empty) {
      this.done = true;
      this.lastVisible = null;
    }
    const list = pushToArray(docs);
    if (list.length < Pages.size) {
      this.lastVisible = null;
      this.done = true;
    }
    this.lastVisible = list[list.length - 1];
    this.loading = false;
    return list;
  }

  @action
  async onScrollSchedule(termKey, majorKey, shiftKey) {
    if (this.fetching || this.done) { return; }
    this.fetching = true;
    const docs = await this.ds.studentAllScheduleRef(termKey, majorKey, shiftKey, this.lastVisible).get().toPromise();
    if (docs.empty) {
      this.done = true;
      this.lastVisible = null;
    }
    const list = pushToArray(docs);
    if (list.length < Pages.size) {
      this.lastVisible = null;
      this.done = true;
    }
    this.lastVisible = list[list.length - 1];
    this.fetching = false;
    return list;
  }


  @action
  async fetchShift() {
    const docs = await this.ds.academicShiftRef().get().toPromise();
    return pushToArray(docs);
  }

  @action
  async fetchBatchSchedules(termKey, batchKey, shiftKey) {
    const docs = await this.ds.studentBatchScheduleRef(termKey, batchKey, shiftKey).get().toPromise();
    return pushToArray(docs);
  }


  @action
  async fetchMajorSchedules(termKey, majorKey, shiftKey) {
    const docs = await this.ds.studentMajorScheduleRef(termKey, majorKey, shiftKey).get().toPromise();
    return pushToArray(docs);
  }

  @action
  async fetchStudentBatch(majorKey, generationKey, shiftKey) {
    console.log('fetchStudentBatch', majorKey, generationKey, shiftKey);
    const docs = await this.ds.generationBatchRef(majorKey, generationKey, shiftKey).get().toPromise();
    if (docs.empty) return null;
    return pushToArray(docs)[0];
  }


  @action
  search(term, field, search) {
    if (search) {
      return this.ds.scheduleSearchRef(term, field, search).valueChanges();
    }
    return this.ds.scheduleRef(term).valueChanges();
  }

  @action
  fetchDataSources(termKey: string) {
    this.loading = true;
    this.ds.scheduleRef(termKey).valueChanges().subscribe(docs => {
      this.dataSources = docs;
      this.loading = false;
    })
  }

  @action
  fetchshift(callback) {
    this.loading = true;
    this.ds.academicShiftRef().valueChanges().subscribe(docs => {
      this.shift = MappingService.orderByDesc(docs, 'order');
      callback(docs)
      this.loading = false;
    })
  }

  @action
  fetchSchedules(termKey, majorKey, carts: Array<any>, enrolls: Array<any>) {
    this.loading = true;
    this.ds
      .schedulesMajorRef(termKey, majorKey)
      .valueChanges()
      .subscribe(doc => {
        let data = []
        const cartKey = carts.map(m => m.course && m.course.schedule_subject.subject.code);
        data = doc.filter(m => cartKey.indexOf(m.schedule_subject && m.schedule_subject.subject.code) === -1)
        const enrollKey = enrolls.map(m => m.courseCode);
        data = data.filter(m => enrollKey.indexOf(m.schedule_subject && m.schedule_subject.subject.code) === -1)
        this.schedules = MappingService.orderBy(data, "schedule_subject.subject.name");;
        this.emptySchedules = data.length === 0;
        this.loading = false;
      });
  }

  @action
  async fetchBatchScheduleByGeneration(generationKey: string, majorKey: string, termKey) {
    this.loading = true;
    this.ds.academicTermDocRef().doc(termKey).collection("academic_schedule_batches", ref => ref
      .where("batch.major.key", "==", majorKey)
      .where("batch.puc_generation.key", "==", generationKey)
    )
      .valueChanges().subscribe(doc => {
        this.batchSchedules = doc;
        this.emptyBatchSchedules = doc.length === 0;
        this.loading = false;
      })
  }

  @action
  async fetchBatchSchedule(batchName: string) {
    const configDoc = await this.ds.academicRef().get().toPromise();
    const { term } = MappingService.pushToObject(configDoc);
    this.loading = true;
    this.ds.academicTermDocRef().doc(term.key).collection("academic_schedule_batches", ref => ref.where("batch.name", "==", batchName)).valueChanges().subscribe(doc => {
      this.batchSchedules = doc;
      this.emptyBatchSchedules = doc.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchAllSchedulesData(termKey, shifkey) {
    this.loading = true;
    this.done = false;
    this.fetching = false;
    this.ds
      .academicTermDocRef().doc(termKey).collection("academic_schedule_batches", ref => ref
        .where("isHeader", "==", true)
        .where('schedule.session.shift.key', '==', shifkey)
        .orderBy("page_key")
        .limit(Pages.size)
      )
      .valueChanges()
      .subscribe(docs => {
        if (docs.length > 0 && docs.length >= Pages.size) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.lastVisible = null;
          this.done = true;
        }
        this.emptySchedules = docs.length === 0;
        this.groups = MappingService.groupByOrderDesc(docs, "batch.name", "batch.puc_generation.generation");
        this.allSchedules = docs;
        this.loading = false;
      });
  }


  @action
  onScroll(termKey, shifkey) {
    if (this.fetching || this.done) return;
    if (!this.lastVisible) return;
    this.fetching = true;
    this.ds
      .academicTermDocRef().doc(termKey).collection("academic_schedule_batches", ref => ref
        .where("isHeader", "==", true)
        .where('schedule.session.shift.key', '==', shifkey)
        .orderBy("page_key", "desc")
        .startAfter(this.lastVisible.page_key)
        .limit(Pages.size)
      )
      .valueChanges()
      .subscribe(docs => {
        if (docs.length > 0 && docs.length >= Pages.size) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.lastVisible = null;
          this.done = true;
        }
        this.allSchedules = this.allSchedules.slice().concat(docs);
        this.groups = MappingService.groupByOrderDesc(this.allSchedules, "batch.key", "batch.puc_generation.generation");
        this.fetching = false;
      });
  }

  @action
  fetchSchedule(termKey, batchKey) {
    return this.ds.academicTermDocRef().doc(termKey).collection("academic_schedule_batches", ref => ref
      .where("batch.key", "==", batchKey)
      .where("isHeader", "==", true)
    )
  }

  @action
  fetchEnrollment(termKey, studentKey, callback) {
    this.loading = true;
    this.ds.studentEnrollmentRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.enrollment = docs;
      this.emptyEnroll = docs.length === 0;
      callback(docs)
      this.loading = false;
    })
  }

  @action
  fetchCourse(termKey, studentKey, callback) {
    this.loading = true;
    this.ds.studentCourseRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.course = docs;
      this.emptyCourse = docs.length === 0;
      callback(docs)
      this.loading = false;
    })
  }

  @action
  async fetchStudentSchedule(termKey, studentKey, shiftKey) {
    this.loading = true;
    const { admission_type, program_academic } = this.selectedAdmission;
    const shiftData = await this.ds.academicShiftRef().doc(shiftKey).get().toPromise();
    this.selectedShift = pushToObject(shiftData);
    switch (admission_type.key) {
      case 2://institute and center
        const batch = this.student[program_academic.category.key];
        const data = await this.ds.instituteBatchRef(batch.key, termKey).get().toPromise();
        this.course = MappingService.orderBy(pushToArray(data), "schedule_subject.book.name");
        this.emptyCourse = data.empty;
        this.loading = false;
        break;
      default://academic program
        this.ds.studentCurrentScheduleRef(termKey, studentKey, shiftKey).valueChanges().subscribe(docs => {
          this.course = MappingService.orderBy(docs, "courseName");
          this.emptyCourse = docs.length === 0;
          this.loading = false;
        })

        break;
    }
  }

  @action
  async fetchStudentScheduleTerm(termKey, studentKey) {
    this.loading = true;
    const { admission_type, program_academic } = this.selectedAdmission;
    switch (admission_type.key) {
      case 2://institute and center
        const batch = this.student[program_academic.category.key];
        const data = await this.ds.instituteBatchRef(batch.key, termKey).get().toPromise();
        this.course = MappingService.orderBy(pushToArray(data), "schedule_subject.book.name");
        this.emptyCourse = data.empty;
        this.loading = false;
        break;
      case 3://Accelerated Term
        this.ds.studentCurrentScheduleTermRef(termKey, studentKey).valueChanges().subscribe(docs => {
          this.course = MappingService.orderBy(docs, "courseName");
          this.emptyCourse = docs.length === 0;
          this.loading = false;
        })
        break;
      default://academic program
        this.ds.studentCurrentScheduleTermRef(termKey, studentKey).valueChanges().subscribe(docs => {
          this.course = MappingService.orderBy(docs, "courseName");
          this.emptyCourse = docs.length === 0;
          this.loading = false;
        })
        break;
    }
  }




  @action
  fetchStudent(studentKey) {
    this.process = true;
    this.ds.studentRef(studentKey).valueChanges().subscribe(doc => {
      this.process = false;
      const { prepaid } = doc;
      this.prepaid = prepaid
      this.student = doc;
    });
  }

  @action
  fetchStudentToArray(studentKey, callback) {
    this.process = true;
    this.ds.studentRef(studentKey).valueChanges().subscribe(doc => {
      this.process = false;
      const { prepaid } = doc;
      this.prepaid = prepaid
      this.student = doc;
      callback(doc);
    });
  }


  @action
  async fetchStudentAdmission(studentKey) {
    this.loading = true;
    const studentData = await this.ds.studentDocument(studentKey).get().toPromise();
    const studentDoc = await MappingService.pushToObject(studentData);
    const { program_academic } = studentDoc;

    const docs = await this.ds.studentAdmissionRef(studentKey).get().toPromise();
    const admission = pushToArray(docs);
    if (!docs.empty) {
      this.admissions = MappingService.orderByDesc(admission, "program.order");
      this.selectedAdmission = this.admissions.filter(m => m.key === program_academic.admissionKey)[0];
      if (!this.selectedAdmission) this.selectedAdmission = this.admissions[0];
      this.loading = false;
      return this.selectedAdmission;
    }
    this.admissions = [];
    this.selectedAdmission = null;
    this.loading = false;
    return null;
  }

  @action
  async fetchAdmission(studentKey, callback) {
    this.loading = true;
    this.ds.studentAdmissionRef(studentKey).valueChanges().subscribe(async docs => {
      const studentData = await this.ds.studentDocument(studentKey).get().toPromise();
      const studentDoc = await MappingService.pushToObject(studentData);
      const { program_academic } = studentDoc;
      this.admissions = MappingService.orderByDesc(docs, "program.order");
      this.selectedAdmission = this.admissions.filter(m => m.key === program_academic.admissionKey)[0];
      if (!this.selectedAdmission) this.selectedAdmission = this.admissions[0];
      this.loading = false;
      callback(this.selectedAdmission)
    });
  }

  @action
  fetchInstituteActiveTerm(key: string, callback) {
    this.process = true;
    this.ds.instituteActiveTermRef(key).valueChanges().subscribe(doc => {
      this.process = false;
      callback(doc);
    })
  }

  @action
  getAdmission(admissionKey, callback) {
    this.process = true;
    this.ds
      .selectedAdmissionRef(admissionKey)
      .valueChanges()
      .subscribe(doc => {
        this.selectedAdmission = doc;
        this.process = false;
        callback(this.selectedAdmission)
      });
  }

  @action
  studentEnroll(enroll: any, studentKey: string, termKey: string, admissionKey: string, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.studentFireRef(studentKey).doc(enroll.key);
    const academicEnrollmentRef = this.ds.academicEnrollmentFireRef().doc(enroll.key);
    const academicTermRef = this.ds.academicTermFireRef(termKey, enroll.schedule.batchKey).doc(enroll.key);
    const studentTranscriptRef = this.ds.studentTranscriptFireRef(studentKey, admissionKey).doc(enroll.key);
    batch.set(studentRef, enroll);
    batch.set(academicEnrollmentRef, enroll);
    batch.set(academicTermRef, enroll);
    batch.set(studentTranscriptRef, enroll);
    batch.commit().then(() => {
      callback(true, null)
      this.process = false;
    }).catch(error => {
      callback(false, error)
      this.process = false;
    })
  }


  @action
  async getStudent(studentKey) {
    this.process = true;
    const data = await this.ds.studentRef(studentKey).get().toPromise();
    this.process = false;
    return pushToObject(data)
  }


  @action
  async getTest(testKey) {
    this.process = true;
    const data = await this.ds.testingRef().doc(testKey).get().toPromise();
    this.process = false;
    return pushToObject(data)
  }


  @action
  updateUnderstandPaymentMethod(studentKey: string, checked) {
    this.process = true;
    const studentRef = this.ds.studentFireS(studentKey);
    studentRef.update({ isUnderstandPaymentMethod: checked, isUnderstandPaymentMethodDate: new Date() }).then(() => {
      this.process = false;
    }).catch(error => {
      this.process = false;
    })
  }
}
