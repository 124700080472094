import { Cart } from './../../stores/cart.store';
import { MatSnackBar } from '@angular/material';
import { Auth } from './../../stores/auth.store';
import { Environment } from './../../stores/environment.store';
import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/stores/student.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  terms: any;

  constructor(public env: Environment,
    public store: Student,
    public cart: Cart,
    private snackBar: MatSnackBar,
    public router: Router,
    public auth: Auth,
  ) { }

  async ngOnInit() {
    this.terms = await this.env.fetchTerms();
    this.auth.fetchUser();
    this.env.fetchConfigToArray(config => {
      this.auth.fetchAccountLink(account => {
        if (account) {
          this.store.fetchStudent(account.studentKey);
          this.store.fetchAdmission(account.studentKey,async (doc) => {
            const { term } = config;
            const { program_academic } = this.store.selectedAdmission;
            this.env.fetchInstituteTermDoc(program_academic.category.key, res => {
              this.store.fetchStudentScheduleTerm(this.env.selectedTerm.key, account.studentKey)
            });
          })
        }
        else {
          this.router.navigate(['/not-found-account']);
        }
      })
    })
  }

  changeTerm(term, studentKey) {
    this.env.selectedTerm = term;
    this.store.fetchStudentScheduleTerm(this.env.selectedTerm.key, studentKey)
  }

  _onAddCourse(event) {
    const { major, major_generation } = this.store.selectedAdmission;
    // this.cart.addToCart(event,this.env.selectedTerm.key,this.auth.student,major,major_generation,(success,error)=>{
    // })

  }

}
