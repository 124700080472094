
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-scan-qrcode',
  templateUrl: './scan-qrcode.component.html',
  styleUrls: ['./scan-qrcode.component.scss']
})
export class ScanQrCodeComponent implements OnInit {
  public qrCodeText: string = null;
  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.qrCodeText = 'http://localhost:4215/scan-qrcode';
  }

  ngOnInit() {
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      // if (this.deviceService.getDeviceInfo().device === "iPhone")
      //   this.document.location.href = 'https://apple.co/2LufNUe';
      if (this.deviceService.getDeviceInfo().device === "Android")
        this.document.location.href = 'https://play.google.com/store/apps/details?id=com.pucstudentapp&fbclid=IwAR1ybhnMMShCWx--LkFMGY_7VvAA-2HMZAa1hejNS1u8wzA56GUxY47nwD8';
    }
  }
}
