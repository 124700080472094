import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Payment } from "src/app/stores/payment.store";
import { ConvertService } from "src/app/services/convert.service";

@Component({
  selector: 'app-wing-payway',
  templateUrl: './wing-payway.component.html',
  styleUrls: ['./wing-payway.component.scss']
})
export class WingPaywayComponent implements OnInit {
  @ViewChild("buttonSubmit")
  btnSubmit;
  @ViewChild("username")
  username;
  // @ViewChild("password") password;
  @ViewChild("rest_api_key")
  rest_api_key;
  @ViewChild("return_url")
  return_url;
  @ViewChild("bill_till_number")
  bill_till_number;
  @ViewChild("amount")
  amount;
  @ViewChild("remark")
  remark;
  @ViewChild("transactionId")
  transactionId;

  //Testing Mode
  userName = "online.puc";
  // passWord = "05ba0faf0c8df06f2693efe3d48fdc10";

  //======Testing
  // apiKey = "bdc38d95737599fe50e9cf8b26f66209bc3a390924ecffdbb1c6a313cf54acbf";

  //======Production
  apiKey = "cc7d143d426d6443a248814528716e3fd6dba4a99939db62b6a8b2bb696b562c";

  // returnUrl = "http://localhost:4214/payments/wing/" + '123456' + "/success";

  //======Testing
  // billTillNumber = 2000;

  //======Production
  billTillNumber = 5124;

  payAmount = 0;
  studentKey: string;
  invoiceKey: string;
  items: any;
  process = true;

  constructor(
    public route: ActivatedRoute,
    public afs: AngularFirestore,
    public store: Payment,
  ) { }

  async ngOnInit() {
    this.route.params.forEach(async (params) => {
      this.studentKey = params["id"];
      this.invoiceKey = params["iid"];

      await this.store.fetchStudentPayment(this.studentKey, this.invoiceKey, (grandTotal) => {
        this.payAmount = ConvertService.toNumber(grandTotal);
        this.username.nativeElement.value = this.userName;
        this.rest_api_key.nativeElement.value = this.apiKey;
        this.return_url.nativeElement.value = "https://p.puc.edu.kh/payments/wing-success/" + this.invoiceKey;
        this.bill_till_number.nativeElement.value = this.billTillNumber;
        this.remark.nativeElement.value = this.invoiceKey;
        this.transactionId.nativeElement.value = this.invoiceKey;
        this.amount.nativeElement.value = this.payAmount;
        this.btnSubmit.nativeElement.submit();
        this.process = false;
      })

      this.process = false;

      // this.studentStore.fetchStudentToArray(this.studentKey, (student) => {
      //   const { key, program_academic } = student;
      //   if (program_academic && program_academic.type.type === 'ACADEMIC') {
      //     this.env.fetchConfigToArray(ls => {
      //       this.env.fetchOptionsToArray(config => {
      //         this.payment.fetchScholarshipByProgram(key, program_academic ? program_academic.scholarshipKey : null, sch => {
      //           this.payment.fetchScholarShiftPenalty(key, (scholar) => {

      //             this.payment.fetchEnrollment(program_academic.admissionKey, student, this.payment.scholarship, this.env.options.lateFee, this.env.selectedTerm, (enrolls) => {

      //               let currentTerm = this.env.selectedTerm;
      //               if (enrolls.length > 0 || this.payment.scholarshipPenalty.length > 0) {
      //                 let programKey = 'null';
      //                 if (this.payment.enroll.length > 0) programKey = this.payment.enroll[0].program.category.key
      //                 this.studentStore.fetchInstituteActiveTerm(programKey, (res) => {
      //                   if (res) currentTerm = res;
      //                   else currentTerm = this.env.selectedTerm;
      //                   this.payment.fetchInvoiceHeader(this.studentKey, this.invoiceKey, res => {
      //                     if (res) {
      //                       const { key, amount } = res;
      //                       this.payAmount = amount;
      //                       this.username.nativeElement.value = this.userName;
      //                       this.rest_api_key.nativeElement.value = this.apiKey;
      //                       this.return_url.nativeElement.value = "https://p.puc.edu.kh/payments/wing-success/" + key;
      //                       this.bill_till_number.nativeElement.value = this.billTillNumber;
      //                       this.remark.nativeElement.value = key;
      //                       this.transactionId.nativeElement.value = key;
      //                       this.amount.nativeElement.value = this.payAmount;
      //                       this.btnSubmit.nativeElement.submit();
      //                       this.process = false;
      //                     }
      //                   })

      //                 })
      //               }

      //             });
      //           })

      //         });
      //       });
      //     })
      //   }
      // })

    });
  }
}