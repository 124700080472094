import { Student } from "./../../stores/student.store";
import { Auth } from "./../../stores/auth.store";
import { Transcript } from "./../../stores/transcript.store";
import { PrintService } from "./../../services/print.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { tabs } from "src/app/dummy/tabs";

@Component({
  selector: "app-grade",
  templateUrl: "./grade.component.html",
  styleUrls: ["./grade.component.scss"]
})
export class GradeComponent implements OnInit {
  process = true;
  id: string;
  tabsLink = tabs.transcript;
  ismobile = false;
  constructor(
    public ps: PrintService,
    public store: Transcript,
    public auth: Auth,
    public student: Student,
    private router: Router,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver
  ) { }

  async ngOnInit() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 736px)');
    this.ismobile = isSmallScreen;
    this.process = true;
    this.route.params.forEach(params => {
      this.id = params["id"];
      this.auth.fetchAccountLink(account => {
        if (account) {
          //account.studentKey
          // n0QPdJLkVLaQ24uycnBP
          this.student.fetchStudent(account.studentKey);
          this.student.fetchAdmission(account.studentKey,async doc => {
            if (this.id) {
              this.student.getAdmission(this.id, admission => {
                const { admission_type } = this.student.selectedAdmission;
                if (admission_type.key === 3) {
                  this.store.fetchTranscript(this.student.student.key, this.id, 1)
                } else this.router.navigate(["/not-found-account"]);
              });
            } else {
              if (this.student.selectedAdmission) {
                const { admission_type } = this.student.selectedAdmission;
                if (admission_type.key === 3) {
                  this.store.fetchTranscript(this.student.student.key,
                    this.student.selectedAdmission.key, 1);
                } else this.router.navigate(["/not-found-account"]);
              }
            }
          });
        } else {
          this.router.navigate(["/not-found-account"]);
        }
      });
    });


  }

  print() {
    this.auth.fetchAccountLink(account => {
      if (account) {
        this.student.fetchStudent(account.studentKey);
        this.student.fetchAdmission(account.studentKey,async doc => {
          if (this.id) {
            this.student.getAdmission(this.id, admission => {
              this.store.fetchTranscriptPrint(this.student.student.key, this.id, 1, (list) => {
                this.ps.print("#print", "a4");
              })
            });
          } else {
            if (this.student.selectedAdmission) {
              this.store.fetchTranscriptPrint(this.student.student.key,
                this.student.selectedAdmission.key, 1, list => {
                  this.ps.print("#print", "a4");
                });
            }
          }
        });
      } else {
        this.router.navigate(["/not-found-account"]);
      }
    });
  }
}
