import { Component, OnInit } from '@angular/core';
import { Auth } from 'src/app/stores/auth.store';
import { Student } from 'src/app/stores/student.store';
import { Environment } from 'src/app/stores/environment.store';
import { Cart } from 'src/app/stores/cart.store';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmEnrollmentComponent } from 'src/app/components/confirm-enrollment/confirm-enrollment.component';
import { ConfirmSuccessComponent } from 'src/app/components/confirm-success/confirm-success.component';
import { ConfirmRequestEnrollmentComponent } from 'src/app/components/confirm-request-enrollment/confirm-request-enrollment.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-enrollment-cart',
  templateUrl: './enrollment-cart.component.html',
  styleUrls: ['./enrollment-cart.component.scss']
})
export class EnrollmentCartComponent implements OnInit {
  ismobile = false;

  constructor(
    public store: Auth,
    public studentStore: Student,
    public env: Environment,
    public cart: Cart,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
    
  ) { }

  async ngOnInit() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 736px)');
    this.ismobile = isSmallScreen;
    this.env.fetchConfigToArray(doc => {
      this.store.fetchAccountLink(acc => {
        this.studentStore.fetchStudentToArray(this.store.student.key, (res) => {
          this.studentStore.fetchAdmission(this.store.student.key,async (add)=>{})
          this.cart.fetchCart(this.env.selectedTerm.key, this.store.student.key);
        })
      })
    })
  }

  _removeCart(item) {
    this.cart.removeCart(item, (success, error) => {
    });
  }

  requestApproval() {
    if(!this.studentStore.selectedAdmission) return;
    let dialogRef = this.dialog.open(ConfirmRequestEnrollmentComponent, {
      data: { title: "Request Pre Enrollment", subTitle: "This courses will request to Faculty member", text: "Request" },
      width: this.ismobile ? '100vw' : '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.cart.studentRequestEnroll(this.cart.data, this.env.selectedTerm.key, this.studentStore.selectedAdmission, (success, error) => {
          if (success) {
            this.router.navigate(['/enrollment/request-success']);
          } else {
            this.snackBar.open(error, "Error");
          }
        });
      }
    });
  }

}
