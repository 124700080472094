import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { Auth } from 'src/app/stores/auth.store';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Payment } from 'src/app/stores/payment.store';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  isUnderstandPaymentMethod: AbstractControl
  studentData;
  testDate;
  constructor(
    public env: Environment,
    public student: Student,
    public auth: Auth,
    private fb: FormBuilder,
    public payment: Payment,
  ) {

  }
  ngOnInit() {
    this.form = this.fb.group({
      isUnderstandPaymentMethod: [false, Validators.required],
    })

    this.auth.fetchAccountLink(async (account: { studentKey: string; }) => {
      if (account) {
        this.studentData = await this.student.getStudent(account.studentKey);
        this.testDate = await this.student.getTest(this.studentData.recent_test_key)
        if (!this.payment.unpaidInvoices) {
          this.payment.fetchStudentInvoices(account.studentKey);
        }
        if (this.studentData.isUnderstandPaymentMethod) {
          this.form.patchValue({
            isUnderstandPaymentMethod: this.studentData.isUnderstandPaymentMethod || false
          })
          this.form.disable()
        }

      }
    })
  }

  _onCheck(event) {
    if (event.checked) {
      this.student.updateUnderstandPaymentMethod(this.studentData.key, event.checked)
    }
  }


}
