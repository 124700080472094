import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { preEnrollStatus } from 'src/app/dummy/status';
import { MappingService } from 'src/app/services/mapping.service';
import { IPreEnrollment } from 'src/app/interfaces/enrollment';
import { ConvertService } from 'src/app/services/convert.service';
import { Environment } from 'src/app/stores/environment.store';
import { Cart } from 'src/app/stores/cart.store';
import { Student } from 'src/app/stores/student.store';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-dialog-schedule',
  templateUrl: './dialog-schedule.component.html',
  styleUrls: ['./dialog-schedule.component.scss']
})
export class DialogScheduleComponent implements OnInit {
  process = false;
  constructor(
    public dialogRef: MatDialogRef<DialogScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public env: Environment,
    public store: Student,
    public cart: Cart,
    private snackBar: MatSnackBar,
    public router: Router,
    private ds: DataService,
    public auth: Auth,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.auth.fetchAccountLink(account => {
      if (account) {
        this.store.fetchStudent(account.studentKey);
      }
    })

  }

  _onAddCourse(course) {
    if (this.cart.data.length === 5){
      let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Mix enroll 5 subjects", subTitle: "Pre enrollment allow only 5 subjects." },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    };
    if (!this.store.student) return;
    let { credit } = this.store.student;
    if (credit || credit > 0) {
      let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data: { title: "Block Enrollment", subTitle: "Your account cannot enroll, you must clear unpaid or penalty payment." },
        width: '35vw',
        disableClose: true,
        role: 'dialog',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    } else {
      const { major, major_generation } = this.store.selectedAdmission;
      const item: IPreEnrollment = {
        key: this.ds.createId(),
        course: MappingService.courseObj(course),
        create_date: new Date(),
        create_date_key: ConvertService.dateKey(),
        status: preEnrollStatus.pending,
        isCompleted: false,
        isRequest: false,
        isPaid: false,
        note: null,
        major: MappingService.majorObj(major),
        page_key: ConvertService.pageKey(),
        generation: ConvertService.toNull(major_generation),
        student: MappingService.studentObj(this.auth.student),
        term: MappingService.termObj(this.env.selectedTerm)
      }
      this.cart.addToCart(item, (success, error) => {
        if (success) {
          this.dialogRef.close()
        } else {
          this.snackBar.open(error, "Error");
        }
      })
    }
  }

}
