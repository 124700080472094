import { Component, OnInit } from '@angular/core';
import { Cart } from 'src/app/stores/cart.store';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { Auth } from 'src/app/stores/auth.store';
import { Payment } from 'src/app/stores/payment.store';
import { InvoiceDialogComponent } from 'src/app/components/invoice-dialog/invoice-dialog.component';
import { MatDialog } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

// import * as crypto from "crypto";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  process = false;
  ismobile = false;
  student;

  constructor(
    public store: Auth,
    public studentStore: Student,
    public env: Environment,
    public payment: Payment,
    public cart: Cart,
    private dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
  ) {

    // Nodejs encryption with CTR
    // const crypto = require('crypto');
    // const algorithm = 'aes-256-cbc';
    // const key = crypto.randomBytes(32);
    // const iv = crypto.randomBytes(16);


    // var hw = this.encrypt("Some serious stuff", key, iv)
  }



  //  encrypt(text, key, iv) {
  //   let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  //   let encrypted = cipher.update(text);
  //   encrypted = Buffer.concat([encrypted, cipher.final()]);
  //   return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
  // }

  //  decrypt(text, key) {
  //   let iv = Buffer.from(text.iv, 'hex');
  //   let encryptedText = Buffer.from(text.encryptedData, 'hex');
  //   let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  //   let decrypted = decipher.update(encryptedText);
  //   decrypted = Buffer.concat([decrypted, decipher.final()]);
  //   return decrypted.toString();
  // }

  _onPrint(item) {
    if (item.scholarship100) return;
    let dialogRef = this.dialog.open(InvoiceDialogComponent, {
      data: { studentKey: item.student.key, headerKey: item.headerRef },
      width: this.ismobile ? '100vw' : '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  ngOnInit() {

    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 736px)');
    this.ismobile = isSmallScreen;
    this.process = true;
    this.store.fetchAccountLink(acc => {
      this.student = acc.student;
      this.studentStore.fetchAdmission(acc.studentKey, (doc) => { })
      this.payment.fetchStudentInvoices(acc.studentKey);
      // this.studentStore.fetchStudentToArray(acc.key, (student) => {
      //   const { key, program_academic } = student;
      //   if (program_academic && program_academic.type.type === 'ACADEMIC') {
      //     this.env.fetchConfigToArray(ls => {
      //       this.env.fetchOptionsToArray(config => {
      //         // this.payment.fetchScholarshipByProgram(key, program_academic ? program_academic.scholarshipKey : null, sch => {
      //         //   this.payment.fetchEnrollment(program_academic.admissionKey, student, this.payment.scholarship, this.env.options.lateFee, this.env.selectedTerm, (res)=>{});
      //         // });
      //         this.payment.fetchScholarshipByProgram(key, program_academic ? program_academic.scholarshipKey : null, sch => {
      //           this.payment.fetchScholarShiftPenalty(key, (scholar) => {

      //             this.payment.fetchEnrollment(program_academic.admissionKey, student, this.payment.scholarship, this.env.options.lateFee, this.env.selectedTerm, (enrolls) => {

      //               // let currentTerm = this.env.selectedTerm;
      //               // if (enrolls.length > 0 || this.payment.scholarshipPenalty.length>0) {
      //               //   let programKey = 'null';
      //               //   if(this.payment.enroll.length>0) programKey = this.payment.enroll[0].program.category.key
      //               //   this.studentStore.fetchInstituteActiveTerm(programKey, (res) => {
      //               //     if (res) currentTerm = res;
      //               //     else currentTerm = this.env.selectedTerm;
      //               //     this.payment.schoolPayment(
      //               //       this.payment.enroll, this.payment.scholarshipPenalty,this.payment.totalScholarship, this.payment.totalLoan, this.payment.totalPrepaid,
      //               //       this.payment.grandTotal, this.payment.gradePrice, this.studentStore.student, currentTerm, (success, headerKey) => {

      //               //         if (success) {
      //               //           this.payAmount = this.payment.grandTotal;
      //               //           this.username.nativeElement.value = this.userName;
      //               //           this.rest_api_key.nativeElement.value = this.apiKey;
      //               //           this.return_url.nativeElement.value = "https://cloud.puc.edu.kh/wing-payment-success/" + headerKey;
      //               //           this.bill_till_number.nativeElement.value = this.billTillNumber;
      //               //           this.remark.nativeElement.value = headerKey;
      //               //           this.transactionId.nativeElement.value = headerKey;
      //               //           this.amount.nativeElement.value = this.payAmount;
      //               //           this.btnSubmit.nativeElement.submit();
      //               //           this.process = false;
      //               //         } else {
      //               //           this.process = false;
      //               //         }
      //               //       });
      //               //   })
      //               // }

      //             });
      //           })

      //         });
      //       });
      //     })
      //   }
      // })
    })
  }

  wingPayment(data) {
    // const { selectedAdmission } = this.studentStore;
    // this.route.navigate([`/payments/wing/${selectedAdmission.student.key}/${data.key}`]);
    // this.studentStore.fetchStudentToArray(acc.key, (student) => {
    //   const { key, program_academic } = student;
    //   if (program_academic && program_academic.type.type === 'ACADEMIC') {
    //     this.env.fetchConfigToArray(ls => {
    //       this.env.fetchOptionsToArray(config => {
    //         // this.payment.fetchScholarshipByProgram(key, program_academic ? program_academic.scholarshipKey : null, sch => {
    //         //   this.payment.fetchEnrollment(program_academic.admissionKey, student, this.payment.scholarship, this.env.options.lateFee, this.env.selectedTerm, (res)=>{});
    //         // });
    //         this.payment.fetchScholarshipByProgram(key, program_academic ? program_academic.scholarshipKey : null, sch => {
    //           this.payment.fetchScholarShiftPenalty(key, (scholar) => {

    //             this.payment.fetchEnrollment(program_academic.admissionKey, student, this.payment.scholarship, this.env.options.lateFee, this.env.selectedTerm, (enrolls) => {

    //               // let currentTerm = this.env.selectedTerm;
    //               // if (enrolls.length > 0 || this.payment.scholarshipPenalty.length>0) {
    //               //   let programKey = 'null';
    //               //   if(this.payment.enroll.length>0) programKey = this.payment.enroll[0].program.category.key
    //               //   this.studentStore.fetchInstituteActiveTerm(programKey, (res) => {
    //               //     if (res) currentTerm = res;
    //               //     else currentTerm = this.env.selectedTerm;
    //               //     this.payment.schoolPayment(
    //               //       this.payment.enroll, this.payment.scholarshipPenalty,this.payment.totalScholarship, this.payment.totalLoan, this.payment.totalPrepaid,
    //               //       this.payment.grandTotal, this.payment.gradePrice, this.studentStore.student, currentTerm, (success, headerKey) => {

    //               //         if (success) {
    //               //           this.payAmount = this.payment.grandTotal;
    //               //           this.username.nativeElement.value = this.userName;
    //               //           this.rest_api_key.nativeElement.value = this.apiKey;
    //               //           this.return_url.nativeElement.value = "https://cloud.puc.edu.kh/wing-payment-success/" + headerKey;
    //               //           this.bill_till_number.nativeElement.value = this.billTillNumber;
    //               //           this.remark.nativeElement.value = headerKey;
    //               //           this.transactionId.nativeElement.value = headerKey;
    //               //           this.amount.nativeElement.value = this.payAmount;
    //               //           this.btnSubmit.nativeElement.submit();
    //               //           this.process = false;
    //               //         } else {
    //               //           this.process = false;
    //               //         }
    //               //       });
    //               //   })
    //               // }

    //             });
    //           })

    //         });
    //       });
    //     })
    //   }
    // })
  }

}
