import { Component, OnInit, Input } from "@angular/core";
import { Student } from "src/app/stores/student.store";

@Component({
  selector: "app-campus-list",
  templateUrl: "./campus-list.component.html",
  styleUrls: ["./campus-list.component.scss"]
})
export class CampusListComponent implements OnInit {
  @Input() data: Array<any>;
  @Input() totalSchedule:Number;
  @Input() totalPayment:Number;

  constructor( public store:Student) {}

  ngOnInit() {
   const {selectedAdmission}=this.store;
   if(selectedAdmission && selectedAdmission.admission_type && selectedAdmission.admission_type.key===2){
     this.data=this.data.filter(m=>m.key!=="enrollment");
   }
  }
}
