// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyDUhSUYS1L4qX6Mb-lqRx0PGan3IkjW4ro",
    // authDomain: "puconline-5db8d.firebaseapp.com",
    // databaseURL: "https://puconline-5db8d.firebaseio.com",
    // projectId: "puconline-5db8d",
    // storageBucket: "puconline-5db8d.appspot.com",
    // messagingSenderId: "727708165818"

    apiKey: "AIzaSyB7RTR716ETAgXojHs96ix6xGQXJuOeUS8",
    authDomain: "puccloud-27563.firebaseapp.com",
    databaseURL: "https://puccloud-27563.firebaseio.com",
    projectId: "puccloud-27563",
    storageBucket: "puccloud-27563.appspot.com",
    messagingSenderId: "688621262550"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
