import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';

@Injectable()
export class Campus {
  @observable data: any = [];
  @observable loading = false;
  @observable selectedCampus: any = null;

  constructor(private ds: DataService) {
  }

  @action
  fetchCampus() {
    this.loading = true;
    this.ds
      .campusRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        if (docs.length > 0) {
          if (!this.selectedCampus) {
            this.selectedCampus = docs[0];
          }
        }
        this.loading = false;
      });
  }
}
