
export const searchFilterBy = [
  { key: "schedule_subject.subject.code", text: "Code" },
  { key: "schedule_subject.subject.name", text: "Name" },
];

export const instructorFilterBy = [
  { key: "full_name", text: "Name" },
  { key: "code", text: "Code" },
];

export const studentFilterBy = [
  { key: "puc_id", text: "PUC ID" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];
