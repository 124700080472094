import { MappingService } from './../services/mapping.service';
import { AuthService } from "./../auth/auth.service";
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService } from '../services/convert.service';
import { IPreEnrollment, IEnrollment } from '../interfaces/enrollment';
import { recordStatus, enrollStatusObj, enrollmentTypes } from '../dummy/status';

@Injectable()
export class Cart {
  @observable public process = false;
  @observable public loading = false;
  @observable public empty = false;
  @observable public data = [];
  @observable public total: number = 0;
  @observable public totalSubject: number = 0;
  @observable public totalPrice: number = 0;

  constructor(private ds: DataService,
    public auth: AuthService) { }

  @action
  removeCart(item: any, callback) {
    this.ds.studentDocument(item.student.key).collection("pre_enrollments").doc(item.key).delete().then(() => {
      callback(true, null)
      this.process = false;
    }).catch(error => {
      callback(false, error)
      this.process = false;
    })
  }


  @action
  addToCart(item: IPreEnrollment, callback) {
    this.process = true;
    this.ds.studentDocument(item.student.key).collection("pre_enrollments").doc(item.key).set(item).then(() => {
      callback(true, null)
      this.process = false;
    }).catch(error => {
      callback(false, error)
      this.process = false;
    })
  }

  @action
  fetchCartToArray(termKey: string, studentKey, callback) {
    this.loading = true;
    this.ds.studentPreEnrollmentRef(termKey, studentKey).valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.total = docs.length;
        callback(docs);
        this.loading = false;
      })
  }

  @action
  studentRequestEnroll(items: Array<IPreEnrollment>, termKey: string, admission: any, callback) {
    this.loading = true;
    const batch = this.ds.batch()
    const studentRef = this.ds.studentFireDocRef();
    const perEnrollmentTermRef = this.ds.academicTermFireDocRef();

    let { major, student, key } = admission;

    batch.set(perEnrollmentTermRef.doc(termKey).collection("pre_enrollment_students").doc(student.key), {
      request_date: new Date(),
      isCompleted: false,
      request_date_key: ConvertService.dateKey(),
      key: student.key,
      admissionKey: key,
      major: major,
      student: MappingService.studentObj(student),
      termKey: termKey,
      facultyKey: major.faculty.key,
      majorKey: major.key,
      totalSubject: this.total,
      page_key: ConvertService.pageKey(),
    })

    items.forEach(item => {
      batch.update(studentRef.doc(item.student.key).collection("pre_enrollments").doc(item.key), {
        request_date: new Date(),
        request_date_key: ConvertService.dateKey(),
        isRequest: true,
      })
    })

    batch.commit().then(() => {
      callback(true, null);
      this.loading = false;
    }).catch(error => {
      callback(false, error)
      this.loading = false;
    })
  }

  @action
  fetchCart(termKey: string, studentKey) {
    this.loading = true;
    this.totalPrice = null;
    this.ds.studentPreEnrollmentRef(termKey, studentKey).valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.total = docs.length;
        if (docs.length > 0) {
          this.totalPrice = docs.reduce(
            (acc, cur) =>
              acc + Number(cur.course.fee.price * cur.course.schedule_subject.credit),
            0
          );
        }
        this.loading = false;
      })
  }

  @action
  fetchRequestedCart(termKey: string, studentKey) {
    this.loading = true;
    this.ds.studentPreEnrollmentRequestRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.data = docs;
      this.totalSubject = docs.length;
      this.empty = docs.length === 0;
      if (docs.length > 0) {
        this.totalPrice = docs.reduce(
          (acc, cur) =>
            acc + Number(cur.course.fee.price * cur.course.schedule_subject.credit),
          0
        );
      }
      this.loading = false;
    })
  }

  @action
  fetchApprovedEnroll(termKey: string, studentKey, callback) {
    this.loading = true;
    this.ds.studentEnrollmentRef(termKey, studentKey).valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.totalSubject = docs.length;
        this.empty = docs.length === 0;
        if (docs.length > 0) {
          this.totalPrice = docs.reduce(
            (acc, cur) =>
              acc + Number(cur.schedule.fee.price * cur.schedule.schedule_subject.credit),
            0
          );
        }
        callback(docs);
        this.loading = false;
      })
  }

}
