import { Observable } from 'rxjs/Observable';
import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';

export class Term {
  @observable loading = false;
  @observable academic_term: Observable<any>;
  @observable english_term: Observable<any>;

  constructor({ loading, academic_term, english_term }) {
    this.loading = loading;
    this.academic_term = academic_term;
    this.english_term = english_term;
  }
}

@Injectable()
export class Terms {
  @observable data: any = [];
  @observable loading = false;
  @observable academic: any = null;

  constructor(private ds: DataService) {
    this.academicTermActive();
  }

  @action
  fetchTerm() {
    this.loading = true;
    this.ds
      .academicTermDocRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.loading = false;
      });
  }

  @action
  academicTermActive() {
    if (this.academic) {
      return;
    }
    this.loading = true;
    this.ds
      .academicTermDocRef()
      .valueChanges()
      .subscribe(docs => {
        this.loading = false;
        if (docs.length > 0) {
          this.academic = docs[0];
        } else {
          this.academic = null;
        }
      });
  }
}
