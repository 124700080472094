import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';

@Injectable()
export class Cashier {
  @observable data: any = [];
  @observable loading = false;

  constructor(private ds: DataService) {
  }

  @action
  fetchCashier() {
    this.loading = true;
    this.ds
      .cashierRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.loading = false;
      });
  }
}
