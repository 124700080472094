import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Genders, previousTestPObj } from 'src/app/dummy/status';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';
import { FireStorageService } from 'src/app/stores/fire-storage.service';
import { Scholarship } from 'src/app/stores/scholarship.store';

@Component({
  selector: 'app-login-shell',
  templateUrl: './login-shell.component.html',
  styleUrls: ['./login-shell.component.scss']
})
export class LoginShellComponent implements OnInit {
  @ViewChild('studentFile') studentFile: ElementRef;
  genderList = Genders;
  previousTestList = previousTestPObj
  examDate = []
  examShift = [];
  form: FormGroup;
  selectedFiles: any;
  currentUpload: any;
  message: any;
  public imagePath;
  imgURL: any;
  uploadPercent: any;
  previousTestNote: boolean = true;
  START_UPLOAD: boolean;
  SELECTED_FILE: any[] = [];
  process: boolean = false;
  student: any;
  previousSchoolRequire: boolean = true;
  previousSchoolDisable: boolean = true;
  constructor(
    private fb: FormBuilder,
    private store: Scholarship,
    private snackBar: MatSnackBar,
    private storage: FireStorageService,
    private ds: DataService,
    private router: Router,
    public auth: Auth,
  ) { }

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    autoplay: true,
  };
  carouselBanner: any;
  index: any;

  async ngOnInit() {
    // this.carouselBanner = {
    //   grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    //   slide: 3,
    //   speed: 400,
    //   // interval: {
    //   //   timing: 3000,
    //   //   initialDelay: 1000
    //   // },
    //   point: {
    //     visible: false
    //   },
    //   load: 2,
    //   loop: true,
    //   touch: true
    // };

    this.form = this.fb.group({
      lastName: [null, Validators.required],
      firstName: [null, Validators.required],
      gender: [this.genderList[0], Validators.required],
      dob: [null, Validators.required],
      phone: [null, Validators.required],
      previousTestNote: [null],
      previousTest: [null, Validators.required],
      previousEnglish: [null, Validators.required],
      // previousEnglish: [ null, this.previousSchoolRequire ? Validators.required : ''],
      yearOfBacII: [null, Validators.required],
      scholarshipDate: [null, Validators.required],
      file: [null, Validators.required],
      shiftOfExam: [null, Validators.required],
    })
    const config = await this.store.getScholarshipExamData();
    this.examDate = config.ScholarshipDate;
    this.examShift = config.ScholarshipExamShift;
    this.form.patchValue({
      scholarshipDate: this.examDate[0],
      shiftOfExam: this.examShift[0],
    })

  }


  displayText(item: any): string {
    return item ? item.text : item;
  }


  displayItem(item: any): string {
    return item;
  }


  removeSelectedFile(file) {
    const newFile = Array.from(this.SELECTED_FILE).filter(f => f.name !== file.name)
    this.SELECTED_FILE = newFile;
  }


  clearFile() {
    this.SELECTED_FILE = [];
    this.studentFile.nativeElement.value = "";
  }

  async uploadFile(event, path) {
    if (event && event.target.files.length > 0) {
      const files = event.target.files;
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        this.SELECTED_FILE.push(file);
      }
      this.studentFile.nativeElement.value = "";
    }
  }

  async _onRegister(f: any, stepper: MatStepper) {
    this.process = true;
    if (this.form.valid) {
      this.form.disable();
      const { lastName, firstName, gender, dob, phone, previousTestNote,
        previousEnglish, previousTest, yearOfBacII, scholarshipDate, shiftOfExam } = f.value;
      let file = null;
      const item: any = {
        lastName,
        firstName,
        gender,
        dob,
        phone,
        previousEnglish,
        previousTest,
        previousTestNote,
        scholarshipDate,
        yearOfBacII, shiftOfExam,
        file,
      }

      if (this.SELECTED_FILE.length > 0) {
        const selectedFiles: any[] = [];
        for (let index = 0; index < this.SELECTED_FILE.length; index++) {
          this.START_UPLOAD = true;
          const file = this.SELECTED_FILE[index];
          const soundPath = `/Register_Scholarship/${lastName}_${firstName}_${file.name}`;
          const soundTask = this.storage.uploadSelectedFile(file, soundPath);
          this.uploadPercent = soundTask.percentageChanges();
          const downloadURL: any = (await soundTask.then(async (f) => {
            return f.ref.getDownloadURL()
          }));
          const fileType = file.type.split('/').slice(0, -1).join('/');
          const files = {
            key: this.ds.createId(),
            path: soundPath,
            name: file.name,
            downloadUrl: downloadURL,
            fileType: file.type,
            type: fileType,
            fileSize: file.size,
          }
          selectedFiles.push(files)
        }
        item.file = selectedFiles[0];
      }
      this.store.registerScholarshipExam(item, async (success, res) => {
        if (success) {
          this.student = res;
          this.START_UPLOAD = false;
          this.studentFile.nativeElement.value = "";
          this.snackBar.open("Register has been successful.", "Done", { duration: 1000 });
          // this.form.reset();
          // this.form.enable();
          this.router.navigate(['/profile']);
          this.process = false;
        }

      })
    }

  }

  onRadioChange(event) {
    // const { key } = event.value;
    // if (key === 1) {
    //   this.previousSchoolRequire = false;
    //   this.form.controls.previousEnglish.disable();
    // } else {
    //   this.previousSchoolRequire = true;
    //   this.form.controls.previousEnglish.enable();
    // }
  }



}
