import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit,Inject, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-enrollment',
  templateUrl: './confirm-enrollment.component.html',
  styleUrls: ['./confirm-enrollment.component.scss']
})
export class ConfirmEnrollmentComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmEnrollmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

  }
  
  onClose(){
    this.dialogRef.close()
  }
}

