import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/dummy/tabs';
import { Environment } from 'src/app/stores/environment.store';
import { Student } from 'src/app/stores/student.store';
import { Cart } from 'src/app/stores/cart.store';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';
import { switchMap, debounceTime, tap } from "rxjs/operators";
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { searchFilterBy } from 'src/app/dummy/search';
import { Pages } from 'src/app/dummy/pages';
import { MappingService } from 'src/app/services/mapping.service';
import { DialogScheduleComponent } from '../dialog-schedule/dialog-schedule.component';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-class-schedules',
  templateUrl: './class-schedules.component.html',
  styleUrls: ['./class-schedules.component.scss']
})
export class ClassSchedulesComponent implements OnInit {
  tabs = tabs.enrollment;
  process = false;
  ismobile = false;

  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = searchFilterBy;
  loading = false;
  termkey;
  constructor(
    public env: Environment,
    public store: Student,
    public cart: Cart,
    private snackBar: MatSnackBar,
    public router: Router,
    private ds: DataService,
    public auth: Auth,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver

  ) { }

  onSelectedShift(item: any) {
    this.store.selectedShift = item;
    this.store.fetchAllSchedulesData(this.termkey, this.store.selectedShift.key);

  }
  ngOnInit() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 736px)');
    this.ismobile = isSmallScreen;

    this.process = true;
    this.env.fetchConfigToArray(config => {
      const { term } = config;
      this.termkey = term.key;
      this.store.fetchshift(data => {
        this.store.fetchAllSchedulesData(term.key, this.store.selectedShift.key);

      })
      this.store.fetchDataSources(term.key);
      this.search.valueChanges
        .pipe(
          debounceTime(Pages.debounceTime),
          tap(() => (this.loading = true)),
          switchMap(value => this.store.search(term.key, this.searchType.value.key, value))
        )
        .subscribe(results => {
          this.store.dataSources = results;
          this.loading = false;
        });
    })

    this.form = this.fb.group({
      searchType: [this.filterBy[0], [Validators.required]],
      search: [null]
    });
    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];

    if (this.store.filterType) {
      this.searchType.patchValue(this.store.filterType);
    }

  }

  displayItem(item: any): string {
    if (this.searchType) {
      const { key } = this.searchType.value;
      return item ? item[key] : item;
    }
    return item ? item.schedule_subject.subject.name : item;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _selectionChange(event) {
    const { value } = event;
    this.store.filterType = value;
  }
  _onFocus(yes) {
    this.formFocus = yes;
  }
  _onSearch(item: any) {
    const { search, searchType } = item;

    if (search && searchType.key) {
      const data = MappingService.filter(this.store.dataSources, searchType.key, search);
      if (data.length > 0) {
        const s = data[0];
        let dialogRef = this.dialog.open(DialogScheduleComponent, {
          data: s,
          panelClass: "register-test-overlay-panel",
          width: this.ismobile ? '100vw' : '35vw',
          height: '100vh',
          disableClose: false,
          role: "dialog"
        });
        dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
        dialogRef.afterClosed().subscribe(result => { })
      }
    }
    else {
      const data = MappingService.filter(this.store.dataSources, searchType.key, search);
      if (data.length > 0) {
        const s = data[0];
        let dialogRef = this.dialog.open(DialogScheduleComponent, {
          data: s,
          panelClass: "register-test-overlay-panel",
          width: this.ismobile ? '100vw' : '35vw',
          height: "100vh",
          disableClose: false,
          role: "dialog"
        });
        dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
        dialogRef.afterClosed().subscribe(result => { })
      }
    }
  }
  _onSelectedClass(item: any) {
    let dialogRef = this.dialog.open(DialogScheduleComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: this.ismobile ? '100vw' : '35vw',
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { })
  }
  _onSelectedCourse(item: any) {
    let dialogRef = this.dialog.open(DialogScheduleComponent, {
      data: item,
      panelClass: "register-test-overlay-panel",
      width: this.ismobile ? '100vw' : '35vw',
      height: "100vh",
      disableClose: false,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => { })
  }

}
