import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from 'src/app/stores/environment.store';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material';
import { ConvertService } from 'src/app/services/convert.service';
import { Auth } from 'src/app/stores/auth.store';
import { InstituteEnrollment } from 'src/app/stores/institute-enrollment.store';

@Component({
  selector: 'app-eng-schedule',
  templateUrl: './eng-schedule.component.html',
  styleUrls: ['./eng-schedule.component.scss']
})
export class EngScheduleComponent implements OnInit {

  studentKey: string;
  admissionKey: string;
  enrollmentKey: string;
  block: boolean = false;
  creditRef: any;
  creditAmount: number;
  scholarshipPenalty: number;
  scholarshipPenaltyRef: any;
  statusRef: any;
  process: boolean = false;
  isValidKey: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public env: Environment,
    private snackBar: MatSnackBar,
    private router: Router,
    public store: InstituteEnrollment,
    private ds: DataService,
    public auth: Auth,

  ) { }

  ngOnInit() {

    this.route.parent.params.forEach(param => {
      this.admissionKey = ConvertService.toNull(param["id"]);
      this.studentKey = ConvertService.toNull(param["studentKey"]);
      if (this.admissionKey) {
        this.process = true;
        this.store.fetchAdmission(this.admissionKey, this.studentKey, req => {
          if (req && this.store.program) {
            this.env.fetchCurrentTermInstituteToObject(this.store.program.key, req => {
            this.store.fetchEnrollment(this.studentKey, this.admissionKey, this.env.selectedTerm.key);
            this.store.fetchStudentSchedule(this.studentKey, this.env.selectedTerm);
            })
          }
          this.process = false;
        })
      }
    })

    // this.auth.fetchUser();
    // this.env.fetchConfigToArray(config => {
    //   this.auth.fetchAccountLink(account => {
    //     if (account) {
    //       this.store.fetchStudent(account.studentKey);
    //       this.store.fetchAdmission(account.studentKey, (doc) => {
    //         const { term } = config;
    //         this.store.fetchStudentSchedule(term.key, account.studentKey)
    //       })
    //     }
    //     else {
    //       this.router.navigate(['/not-found-account']);
    //     }
    //   })
    // })
    
  }

  _onSelectedChange(event) {

  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }

  
}
