import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Holiday } from 'src/app/stores/holiday.store';
import * as moment from "moment";

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  currentYear;
  currentDay;
  constructor(
    public store: Holiday,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    const startOfYear = moment().startOf('year').format("YYYYMMDD");
    const endOfYear   = moment().endOf('year').format("YYYYMMDD");
    this.currentYear = moment().year();
    this.currentDay = moment().format("YYYYMMDD");
    this.store.fetchHoliday(startOfYear, endOfYear);
  }

}
