import { AbstractControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class ConvertService {
  constructor() {}

  static sum(data: Array<any>, field: string) {
    if (!data) return 0;
    return data.reduce((a, b) => a + Number(b[field]), 0)
  }

  static numberOnly(sender: any) {
    let numb = sender.match(/\d/g);
    if (numb) {
      numb = numb.join("");
    } else {
      numb = 0;
    }
    return this.toNumber(numb);
  }
  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true);
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name;
      var day2 = b.name;
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = "";
    daysInput.forEach(item => {
      if (item.name === "monday") result = result + "Monday / ";
      else if (item.name.toLowerCase() === "tuesday")
        result = result + "Tuesday / ";
      else if (item.name.toLowerCase() === "friday")
        result = result + "Friday / ";
      else if (item.name.toLowerCase() === "saturday")
        result = result + "Saturday / ";
      else if (item.name.toLowerCase() === "sunday")
        result = result + "Sunday / ";
      else if (item.name.toLowerCase() === "thursday")
        result = result + "Thursday / ";
      else result = result + "Wednesday / ";
    });
    if (result.length > 3) result = result.substring(0, result.length - 3);
    return result;
  }

  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
  }
  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }
  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(2));
  }
  static isNullUndefinedEmpty(value) {
    if (value === null || value === "" || value === undefined) {
      return true;
    }
    return false;
  }
  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }
  static yearSuffix() {
    return moment()
      .format("YY")
      .toString();
  }
  static addWeek(interval: number) {
    return moment()
      .add(interval, "week")
      .toDate();
  }

  static addMonth(interval: number) {
    return moment()
      .add(interval, "month")
      .toDate();
  }

  static generate_sample_invoiceNo(config) {
    const number =
      moment()
        .format("YYMMDD")
        .toString() + (config + 1).toString();
    return number;
  }

  static pageKey() {
    return Number(moment().format("YYYYMMDDHHmmss"));
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format("YYYYMMDD"));
  }

  static toDateStringKey(date: Date) {
    return String(moment(date).format("YYYYMMDD"));
  }

  static dateKey() {
    return Number(moment().format("YYYYMMDD"));
  }

  static hourToNumber(time) {
    return Number(
      moment(time, "hh:mm a")
        .format("HH")
        .toString() +
      "." +
      moment(time, "hh:mm a")
        .format("mm")
        .toString()
    );
  }

  static toHour(time) {
    return moment(time, "hh:mm a").format("hh:mm a");
  }

  static generate_testing_invoiceNo(config) {
    var number =
      moment()
        .format("YYMMDD")
        .toString() + (config.invoice_shufit + 1).toString();
    return Number(number);
  }

  static generate_puc_id(config) {
    var number =
      moment()
        .format("YY")
        .toString() + (config.puc_id + 1).toString();
    return number;
  }

  static addDays(totalDay: number) {
    return moment()
      .add(totalDay, "days")
      .toDate();
  }

  // static getDaySchedule(days) {
  //   var daysInput = Object.keys(days).map(function (key) {
  //     return { name: key, value: days[key] };
  //   });

  //   daysInput = daysInput.filter(m => m.value === true)
  //   daysInput.sort((a: any, b: any) => {
  //     var day1 = a.name
  //     var day2 = b.name
  //     if (daysOfWeek[day1] < daysOfWeek[day2]) {
  //       return -1;
  //     } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //   let result = '';
  //   daysInput.forEach(item => {
  //     if (item.name === 'monday')
  //       result = result + 'Monday / '
  //     else if (item.name.toLowerCase() === 'tuesday')
  //       result = result + 'Tuesday / '
  //     else if (item.name.toLowerCase() === 'friday')
  //       result = result + 'Friday / '
  //     else if (item.name.toLowerCase() === 'saturday')
  //       result = result + 'Saturday / '
  //     else if (item.name.toLowerCase() === 'sunday')
  //       result = result + 'Sunday / '
  //     else if (item.name.toLowerCase() === 'thursday')
  //       result = result + 'Thursday / '
  //     else
  //       result = result + 'Wednesday / '
  //   })
  //   if (result.length > 3)
  //     result = result.substring(0, result.length - 3)
  //   return result;
  // }
}

export const daysOfWeek = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};
