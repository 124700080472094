import { Auth } from './../../stores/auth.store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  hide = true;
  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  error: any;
  process = false;
  constructor(
    private fb: FormBuilder,
    public auth: Auth,
    private router: Router
  ) {
    this.auth.canActive(doc => {
      if (doc) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
  }

  _onSignIn(f: any): void {
    this.error = null;
    this.process = true;
    if (this.form.valid) {
      const {email,password}=f;
      this.auth.signIn(email, password,(success,error) => {
        if (success) {
          this.process = false;
        this.router.navigate(['/']);
        }
        else{
          this.process = false;
          this.error = error.message;
        }
      });
    } else {
      this.process = false;
      this.error = 'Please enter a valid PUC ID and Password.';
    }
  }
}
