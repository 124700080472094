import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { toJS } from 'mobx';

@Component({
  selector: 'app-student-enrollment-cart',
  templateUrl: './student-enrollment-cart.component.html',
  styleUrls: ['./student-enrollment-cart.component.scss']
})
export class StudentEnrollmentCartComponent implements OnInit {
  @Input() data: Array<any>;
  @Input() loading:boolean;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
