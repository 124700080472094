import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";


@Injectable()
export class Schedule {
  @observable data: Array<any>;
  @observable loading = false;
  @observable process = null;
  @observable empty = false;
  @observable totalCourse = 0;

  constructor(private ds: DataService) { }

  @action
  fetchSchedule(termKey, studentKey) {
    this.loading = true;
    this.ds.studentScheduleRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "subject.subject.name");
      this.totalCourse = docs.length;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchScheduleDocument(termKey, scheduleKey) {
    return this.ds.academicTermDocRef().doc(termKey).collection("schedules").doc(scheduleKey);
  }


}