import { Payment } from 'src/app/stores/payment.store';
import { Component, OnInit, Input } from '@angular/core';
import { invoiceTypesObj } from 'src/app/dummy/status';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {
  @Input() user: any;
  @Input() show:boolean=true;
  
  paymentDate = new Date();
  paymentType = invoiceTypesObj;

  constructor(public store: Payment) { }

  ngOnInit() {
     
  }

}
