import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from '../services/data.service';
import { observable, action } from 'mobx';
import { Injectable } from '@angular/core';

@Injectable()
export class Holiday {
    @observable
    public data = null;
    public holidayCurrentYear = null;
    @observable
    public loading = false;
    @observable
    public empty = false;
    @observable
    public process = false;
    @observable
    public fetching = false;
    @observable
    public done = false;
    @observable
    public groups = null;
    public lastVisible: any = new Date();

    constructor(private ds: DataService) {}

    @action
    fetchToArray(callback) {
      this.process = true;
      this.ds.holidayRef()
        .valueChanges()
        .subscribe(docs => {
         callback(docs);
         this.process = false;
        });
    }

    @action
    fetchData() {
      this.loading = true;
      this.done = false;
      this.ds
        .holidayRef()
        .valueChanges()
        .subscribe(docs => {
          if (docs.length) {
            this.lastVisible = docs[docs.length - 1];
          } else {
            this.lastVisible = null;
            this.done = true;
          }
          this.empty = docs.length === 0;
          this.data = docs;
          this.groups=MappingService.groupByOrderDesc(docs,"year","year")
          this.loading = false;
        });
    }


    @action
    fetchHoliday(startOfYear, endOfYear) {
      this.loading = true;
      this.done = false;
      this.ds
        .holidayYearRef(startOfYear, endOfYear)
        .valueChanges()
        .subscribe(docs => {
          this.holidayCurrentYear = docs;
          this.empty = docs.length === 0;
          this.loading = false;
        });
    }
    @action
    onScroll(e) {
      if (this.fetching || this.done) { return; }
      this.fetching = true;
      this.ds
        .holidayFetchRef(this.lastVisible)
        .valueChanges()
        .subscribe(docs => {
          if (docs.length) {
            this.lastVisible = docs[docs.length - 1];
          } else {
            this.done = true;
          }
          this.data = this.data.slice().concat(docs);
          this.fetching = false;
        });
    }

   
  }
