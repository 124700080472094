import { Cart } from './../../../stores/cart.store';
import { Environment } from './../../../stores/environment.store';
import { Auth } from './../../../stores/auth.store';
import { Student } from './../../../stores/student.store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-enrollment-route',
  templateUrl: './enrollment-route.component.html',
  styleUrls: ['./enrollment-route.component.scss']
})
export class EnrollmentRouteComponent implements OnInit {
  loading = true;
  terms: any;
  shifts: any;
  constructor(
    public store: Student,
    private auth: Auth,
    private router: Router,
    public env: Environment,
    public cart: Cart,
    public dialog: MatDialog,
    private ds: DataService,
    private snackBar: MatSnackBar,


  ) { }

  async ngOnInit() {
    this.terms = await this.env.fetchTerms();
    this.shifts = await this.store.fetchShift()
    this.loading = false;
  }

  changeTerm(item) {
    const { key } = item;
    const { selectedShift } = this.store;
    this.router.navigate([`/enrollment/${this.env.scheduleType}/${key}/${selectedShift.key}`])
  }

  changeShift(item) {
    const { key } = item;
    const { selectedTerm } = this.env;
    this.router.navigate([`/enrollment/${this.env.scheduleType}/${selectedTerm.key}/${key}`])
  }


  _removeCart(item) {
    this.cart.removeCart(item, (success, error) => { });
  }

  _confirmEnroll(data) {
    // let dialogRef = this.dialog.open(ConfirmEnrollmentComponent, {
    //   data: data,
    //   width: '35vw',
    //   disableClose: true,
    //   role: 'dialog',
    // });
    // dialogRef.afterClosed().subscribe(async result => {
    //   if (result === 'yes') {
    //     const settings=await this.env.fetchOption();
    //     const { schedule_subject, session , payment_type, scheduleType} = data;
    //     const { selectedAdmission, } = this.store
    //     const { major, program } = selectedAdmission;
    //     const { code } = schedule_subject.subject;
    //     const fee = MappingService.getFeeRef(settings, code, data.fee, payment_type, scheduleType);
    //     const price = MappingService.getForeignerFee(this.store.student, settings, fee);
    //     const grandTotal = price * schedule_subject.credit;
    //     const enrollment: IEnrollment = {
    //       key: this.ds.createId(),
    //       isFreshmen: false,
    //       admissionKey: selectedAdmission.key,
    //       create_date: new Date(),
    //       create_by: this.auth.student,
    //       status: recordStatus.active,
    //       enroll_status: enrollStatusObj.add,
    //       enrollment_type: enrollmentTypes.academic,
    //       page_key: ConvertService.pageKey(),
    //       enroll_date: new Date(),
    //       enroll_date_key: ConvertService.dateKey(),
    //       student: MappingService.studentObj(this.store.student),
    //       subject: MappingService.scheduleSubjectObj(schedule_subject),
    //       fee: MappingService.feeObj(fee),
    //       campus: null,
    //       shift: session ? session.shift : null,
    //       major: MappingService.majorObj(major),
    //       isStudentEnroll: false,
    //       courseCode: schedule_subject.subject.code,
    //       courseName: schedule_subject.subject.name,
    //       credits_attempted: schedule_subject.credit,
    //       credits_calculator: 0,
    //       credits_earned: 0,
    //       gradePoint: 0,
    //       gradeRef: null,
    //       isCompleted: false,
    //       active: true,
    //       isEnroll: false,
    //       included: true,
    //       isImport: false,
    //       isShow: true,
    //       labHours: schedule_subject.labHours,
    //       lecHours: schedule_subject.lecHours,
    //       overall_grade: null,
    //       program: program,
    //       term: MappingService.termObj(this.env.selectedTerm),
    //       schedule: MappingService.transcriptObj(data),
    //       isApproval: true,
    //       isPaid: false,
    //       price: fee.price,
    //       qty: schedule_subject.credit,
    //       total: grandTotal,
    //       scholarship: null,
    //       scholarshipRef: null,
    //       loan: null,
    //       prepaid: null,
    //       installment: null,
    //       grand_total: grandTotal,
    //       submit_grade: false,
    //       transfer_in: false,
    //     }
    //     this.store.ad(enrollment, this.auth.student.key, this.env.selectedTerm.key, this.store.selectedAdmission.key, (success, error) => {
    //       if (success) {
    //         this.snackBar.open('This course has been enrolled.', 'done', { duration: 2000 });
    //       }
    //       else {
    //         this.snackBar.open(error, 'Error');
    //       }
    //     })
    //   }
    // });
  }

  requestApproval(){

  }

}
