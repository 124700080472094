import { MappingService } from 'src/app/services/mapping.service';
import { Component, OnInit, Input } from '@angular/core';
import { Schedule } from 'src/app/stores/schedule.store';

@Component({
  selector: 'app-mat-schedule',
  templateUrl: './mat-schedule.component.html',
  styleUrls: ['./mat-schedule.component.scss']
})
export class MatScheduleComponent implements OnInit {
  @Input() schedule:any;
  @Input() termKey:any;

  public item:any;
  constructor(private store:Schedule) { }

   ngOnInit() {
    const {schedule,program_type}=this.schedule;
    // if(program_type && program_type.key==='institute') return;
    // const scheduleDoc=await this.store.fetchScheduleDocument(this.termKey,schedule.key).get().toPromise();
    // this.item=MappingService.pushToObject(scheduleDoc);
  }

}
