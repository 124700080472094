export const exploreItems = [
  {
    key: "schedules",
    name: "My Schedules",
    memo: "Course",
    description:
      "A schedule is a plan that gives a list of events or tasks and the times at which each one should happen or be done.",
    icon: "schedule",
    router:"/schedule"
  },
  {
    key: "enrollment",
    name: "Enrollment",
    memo: "Subject",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  },
  
  
  {
    key: "payments",
    name: "Payments",
    memo: "Invoice",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  }
  ,
  {
    key: "transcript",
    name: "Transcript",
    memo: "Program",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  }
];


export const explores = {
  schedule: {
    key: "schedule",
    name: "Schedule",
    description:
      "A schedule is a plan that gives a list of events or tasks and the times at which each one should happen or be done.",
    icon: "schedule"
  },
  enrollment: {
    key: "enrollment",
    name: "Enrollments",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  },
  payment: {
    key: "payments",
    name: "Payment",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  }
  ,
  transcription: {
    key: "transcripts",
    name: "Transcripts",
    description:
      "Matriculation, the process of initiating attendance to a school",
    icon: "schedule"
  }
};
